import React, { Component } from 'react'
import { Menu } from 'antd';
import Case1 from './Case1'
import Case2 from './Case2'
import Case3 from './Case3'
import Case4 from './Case4'
import './index.css'
// import { CSSTransition, TransitionGroup } from "react-transition-group";


export default class Star extends Component {
    state = {
        show: true,
        current: 'ldwn',
        iconimg1: true,
        iconimg2: false,
        iconimg3: false,
        iconimg4: false,
        colors: '1',
    };
    handleClick = e => {
        this.setState({ current: e.key });
    }
    handleClick1 = () => {
        this.setState({
            iconimg1: true,
            iconimg2: false,
            iconimg3: false,
            iconimg4: false,
            colors: '1',
        });
    };
    handleClick2 = () => {
        this.setState({
            iconimg1: false,
            iconimg2: true,
            iconimg3: false,
            iconimg4: false,
            colors: '2',
        });
    };
    handleClick3 = () => {
        this.setState({
            iconimg1: false,
            iconimg2: false,
            iconimg3: true,
            iconimg4: false,
            colors: '3',
        });
    };
    handleClick4 = () => {
        this.setState({
            iconimg1: false,
            iconimg2: false,
            iconimg3: false,
            iconimg4: true,
            colors: '4',
        });
    };
    render() {

        const { current } = this.state;
        return (
            <div>
                <div className="starheader">
                    <div className="iconcenter">
                        <Menu selectedKeys={[current]} mode="horizontal" onClick={this.handleClick}>
                            <Menu.Item key="ldwn" onClick={this.handleClick1}>
                                <div className={this.state.iconimg1 ? 'icon1' : 'icon11'}></div>
                                <h2 className="righth2">中国计量大学—量大微脑</h2>
                            </Menu.Item>
                            <Menu.Item key="smsk" onClick={this.handleClick2}>
                                <div className={this.state.iconimg2 ? 'icon2' : 'icon22'}></div>
                                <h2>四明湖水库智慧水利</h2>
                            </Menu.Item>
                            <Menu.Item key="xfds" onClick={this.handleClick3}>
                                <div className={this.state.iconimg3 ? 'icon3' : 'icon33'}></div>
                                <h2>智慧园区安防综管平台</h2>
                            </Menu.Item>
                            <Menu.Item key="nxgz" onClick={this.handleClick4}>
                                <div className={this.state.iconimg4 ? 'icon4' : 'icon44'}></div>
                                <h2 style={{ marginTop: '0' }}>南浔古镇数字沙盘</h2>
                            </Menu.Item>
                        </Menu>
                    </div>
                </div>
                <div className="starfooter">
                    {(() => {
                        switch (this.state.colors) {
                            case "1": return <Case1 />;
                            case "2": return <Case2 />;
                            case "3": return <Case3 />;
                            case "4": return <Case4 />;
                            default: return null;
                        }
                    }
                    )()}
                    <div className="platform"></div>
                </div>
            </div>
        )
    }
}
