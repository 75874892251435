import React, { Component } from 'react'
import { Button } from 'antd';
import './index.scss'
import { Link } from 'react-router-dom';

export default class CasesM extends Component {
    render() {
        return (
            this.props.casem.map(item => {
                return (
                    <div className="CasesM" key={item.title}>
                        <img src={item.img} alt="" />
                        <h1>{item.title}</h1>
                        <ul>
                            <li>{item.li1}</li>
                            <li>{item.li2}</li>
                            <li>{item.li3}</li>
                            <li>{item.li4}</li>
                        </ul>
                        <Link to={item.pathm}>
                        <Button type="primary" shape="round" className="casesmbutton">
                            <span>查看详情</span>
                        </Button>                        
                        </Link>
                    </div>
                )
            })

        )
    }
}
