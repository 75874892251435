import React, { Component } from 'react'
import { Breadcrumb } from 'antd';
import Othertext from './Othertext'
import './index.css'

export default class index extends Component {
    state = {
        dynamich1: '生态伙伴沙龙第四站・深圳',
        dynamich2: '生态伙伴沙龙第四站・深圳',
        dynamich3: '生态伙伴沙龙第四站・深圳',
        dynamich4: '生态伙伴沙龙第四站・深圳',
        dynamicp1: '据说SuperAPI又双叒更新啦！这次会带来哪些新功能，又有哪些高阶玩法？',
        dynamicp2: '2据说SuperAPI又双叒更新啦！这次会带来哪些新功能，又有哪些高阶玩法？',
        dynamicp3: '3据说SuperAPI又双叒更新啦！这次会带来哪些新功能，又有哪些高阶玩法？',
        dynamicp4: '4据说SuperAPI又双叒更新啦！这次会带来哪些新功能，又有哪些高阶玩法？',
    }
    render() {
        return (
            <div>
                <div className="partner" style={{height:'1600px'}}>
                    <div className="partnerBread">
                        <Breadcrumb separator=">">
                            <Breadcrumb.Item href="https://xuanyantech.com/#/TheHome">首页</Breadcrumb.Item>
                            <Breadcrumb.Item href="https://xuanyantech.com/#/ThePartner3">企业动态</Breadcrumb.Item>
                            <Breadcrumb.Item href="https://xuanyantech.com/#/ThePartner3">文章详情</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div className="partnertitle">
                        <h1 style={{ fontWeight: 'bold' }}>百度如流成2021百度世界最惊喜彩蛋 智能会议纪要炸场</h1>
                        <p>发布日期 2021/08/19/14:52</p>
                    </div>
                    <div className="partnertext">
                        <img src={require('@assets/3000.jpg').default} style={{ width: '50%',marginBottom:'30px' }} alt="" />
                        <p>“3D+AI”跳水辅助训练系统，精准量化评估动作;新疆国网电力AI巡检，改善工作环境拉高工作效率;丽江城市大脑，主动出击给游客最优质的体验……人工智能的落地开花，让我们的生活更加美好。8月18日，2021百度世界大会召开，展示百度AI的诸项先进成果，科技感满满。新一代智能工作平台如流也在会上大显身手，全程“听音辨义”记录会议要点，同时智能抓取重要信息，生动展现了百度AI在日常工作场景中的应用。</p>
                        <p>
                        目前以人工智能为代表的新一代信息技术蓬勃发展，驱动新一轮科技革命和产业变革。AI的价值并不局限于业务的生产环节，对于企业组织成长进化也在发挥着积极的作用，具体表现在企业沟通协同、创新提效、员工成长进步等各个方面。
                        </p>
                        <p>不仅如此，百度如流的智能会议功能还支持会前智能预约、会中智能会议纪要、会后自动生成待办等，这些功能的实现，将员工从繁复冗杂的工作解放出来，大幅提升了办公效率，让他们能够将注意力集中到更有价值的工作当中。</p>
                        <p>事实上，广受好评的智能会议功能正是基于如流“AI+知识管理”的产品理念所设计出的，它极大地帮助了企业实现创新提效，同时，知识的创造、沉淀、流动、应用也将助力员工个人技能的飞速提升，最终实现员工个人进步与企业发展的双赢。
                        </p>
                        <p>未来已来，人工智能的持续快速发展，成为了推动经济高质量发展的重要引擎，企业的发展更离不开办公组织的革新。百度如流能够帮助各企业行业实现工作的智能化升级，打造智能时代的核心竞争力，期待能够携手更多合作伙伴，拥抱科技，实现合作共赢。
                        </p>
                        <h3>注明：本公众号转载文章仅用于分享，不用于任何商业用途。如涉及版权问题，敬请后台联络授权或议定合作，我们会按照版权法规定第一时间为您妥善处理。</h3>
                    </div>
                    <div className="articles">
                        <h1>其他文章</h1>
                    </div>
                    <Othertext />
                </div>
            </div>

        )
    }
}


