import React, { Component } from 'react'
import { Button } from 'antd';
import './index.scss'
import { Link } from 'react-router-dom';

export default class ManyBox extends Component {
    render() {
        return (
            this.props.Many.map(item => {
                return (
                    <div className="AR" style={{ marginRight: item.marginRight + '%' }} key={item.title}>
                        <div className="ARimg">
                            <img src={item.img} alt="" />
                        </div>
                        <h1>{item.title}</h1>
                        <p>{item.p}</p>
                        <Link to="/TheSolutionM">
                            <Button type="primary" shape="round" style={{ width: '60px', height: '20px', backgroundColor: '#2468F2' }}>
                                <span>立即查看</span>
                            </Button>
                        </Link>
                    </div >
                )
            })
        )
    }
}
