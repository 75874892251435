import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import './index.scss'

export default class DynamicM extends Component {
    state = {
        DynamicM1:
        {
            title: '“数字孪生城市”来了',
            p: '在一座物理世界的实体城市之外，是否还可能存在另一座虚拟世界的数字城市？',
            day: '7月6日'
        }
        ,
        DynamicM2:
        {
            title: '数据“活”了，腾讯位置服务推出移动端3D数据可视化图层',
            p: '大数据能力在应用端逐渐普及,在保证数据完整性的同时……',
            day: '8月18日'
        }
        ,
        DynamicM3:
        {
            title: '百度如流成2021百度世界最惊喜彩蛋 智能会议纪要炸场',
            p: '8月18日，2021百度世界大会召开，展示百度AI的诸项先进成果……',
            day: '8月19日'
        }
        ,
        DynamicM4:
        {
            title: '连接虚拟与现实的数字桥梁 NVIDIA开启元宇宙的无限想象',
            p: 'NVIDIA是全球图形运算行业和视觉运算行业的领袖，也是GPU的发明者。',
            day: '8月19日'
        }
        ,
    }
    render() {
        return (
            <div className="DynamicM">
                <div className="DynamicMtitle">
                    <h1>企业动态</h1>
                </div>
                {/* 第一个动态 */}
                <Link to="/ThePartnerM">
                    <div className="DynamicMbox">
                        <img src={require('@assets/动态1.png').default} alt="" />
                        <div className="DynamicMtxt">
                            <h2>{this.state.DynamicM1.title}</h2>
                            <span>{this.state.DynamicM1.day}</span>
                            <p>{this.state.DynamicM1.p}</p>
                        </div>
                    </div>
                </Link>
                {/* 第二个动态 */}
                <Link to="/ThePartnerM2">
                    <div className="DynamicMbox">
                        <img src={require('@assets/动态2.png').default} alt="" />
                        <div className="DynamicMtxt">
                            <h2>{this.state.DynamicM2.title}</h2>
                            <span>{this.state.DynamicM2.day}</span>
                            <p>{this.state.DynamicM2.p}</p>
                        </div>
                    </div>
                </Link>
                {/* 第三个动态 */}
                <Link to="/ThePartnerM3">
                    <div className="DynamicMbox">
                        <img src={require('@assets/动态3.png').default} alt="" />
                        <div className="DynamicMtxt">
                            <h2>{this.state.DynamicM3.title}</h2>
                            <span>{this.state.DynamicM3.day}</span>
                            <p>{this.state.DynamicM3.p}</p>
                        </div>
                    </div>
                </Link>
                <Link to="/ThePartnerM4">
                    <div className="DynamicMbox">
                        <img src={require('@assets/动态444.png').default} alt="" />
                        <div className="DynamicMtxt">
                            <h2>{this.state.DynamicM4.title}</h2>
                            <span>{this.state.DynamicM4.day}</span>
                            <p>{this.state.DynamicM4.p}</p>
                        </div>
                    </div>
                </Link>
            </div>
        )
    }
}
