import React, { Component } from 'react'
import { Breadcrumb } from 'antd';
import PubSub from 'pubsub-js'

export default class Largeg extends Component {
    state = {
        largetitle: '四明湖水库智慧水利',
    }
    search = () => {
        PubSub.publish('shuju', {
            changemenu1: false,
            changemenu2: false,
            changemenu3: true,
            changemenu4: false,
            changemenu5: false,
            changemenu6: false,
        })
    }
    render() {
        return (
            <div className="largebody">
                <Breadcrumb className="Bread" separator='>'>
                    <Breadcrumb.Item href="https://xuanyantech.com/#/TheHome">
                        首页
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href="https://xuanyantech.com/#/Thecaseshows" onClick={this.search}>明星案例推荐</Breadcrumb.Item>
                    <Breadcrumb.Item href="#">{this.state.largetitle}</Breadcrumb.Item>
                </Breadcrumb>
                <div className="largetitle">
                    <h1>{this.state.largetitle}</h1>
                </div>
                <div className="largetxt">
                    <p> 智慧水利：为管理和决策者掌握水库环境资源的布局分布、实时动态变化、环境监管的数据联动、应急事故的指挥调度等提供科学的依据。</p>
                    <p> 核心模块：周边水位线监测报警、水流量统计分析监测、闸位计统计分析监测、蒸发站统计分析监测、墒情站统计分析监测、雨量数据计分析监测、大坝渗流观测统计分析、坝面变形观测统计分析、水库安防监控
                        硕士培养高校第一名，2020年列软科中国大学排名硕士点高校第一。</p>
                    <img src={require('@assets/四明湖.png').default} alt="" />
                    <img src={require('@assets/四明湖1.png').default} alt="" />
                </div>
            </div>
        )
    }
}
