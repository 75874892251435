import React, { Component } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import sjlbt from '@assets/手机轮播图.png'
import './index.scss'
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

export default class Shuffling extends Component {
    render() {
        return (
            <div className="shuffling">
                <Swiper
                    spaceBetween={0}
                    slidesPerView={1}
                    pagination={{ clickable: true }}
                    autoplay={{
                        delay: 3000, //自动滚屏速度
                        disableOnInteraction: false,  //false: 鼠标操作屏幕后继续自动滚屏
                      }}
                    style={{ width: '100%' }}
                >
                    <SwiperSlide><img src={sjlbt} alt="" style={{ width: '100%' }} /></SwiperSlide>
                    <SwiperSlide><img src={sjlbt} alt="" style={{ width: '100%' }} /></SwiperSlide>
                    <SwiperSlide><img src={sjlbt} alt="" style={{ width: '100%' }} /></SwiperSlide>
                    <SwiperSlide><img src={sjlbt} alt="" style={{ width: '100%' }} /></SwiperSlide>
                </Swiper>
            </div>
        );
    }
}
