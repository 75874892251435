import React, { Component } from 'react'
import './index.css'

export default class Culture extends Component {
    state = {
        change1: true,
        change2: false,
        change3: false,
        change4: false,
    }
    aboutchange1 = () => {
        this.setState({
            change1: true,
            change2: false,
            change3: false,
            change4: false,
        })
    }
    aboutchange2 = () => {
        this.setState({
            change1: false,
            change2: true,
            change3: false,
            change4: false,
        })
    }
    aboutchange3 = () => {
        this.setState({
            change1: false,
            change2: false,
            change3: true,
            change4: false,
        })
    }
    aboutchange4 = () => {
        this.setState({
            change1: false,
            change2: false,
            change3: false,
            change4: true,
        })
    }
    render() {
        return (
            <div>
                <div className={this.state.change1 ? 'cultrueboxbig' : 'cultruebox'} onMouseMove={this.aboutchange1}>
                    <div className={this.state.change1 ? 'cultrueheaderbig' : 'cultrueheader'}>
                    诚信：
                    </div>
                    <ul>
                        <li>{this.state.change1 ? '对外诚实守信' : ''}</li>
                        <li>{this.state.change1 ? '对内说到做到' : ''}</li>
                        <li>{this.state.change1 ? '工作中敢于提出问题' : ''}</li>
                    </ul>
                </div>
                <div className={this.state.change2 ? 'cultrueboxbig' : 'cultruebox'} onMouseMove={this.aboutchange2}>
                    <div className={this.state.change2 ? 'cultrueheaderbig' : 'cultrueheader'}>
                    专注：
                    </div>
                    <ul>
                        <li>{this.state.change2 ? '专注做到极致' : ''}</li>
                        <li>{this.state.change2 ? '对于品质专注' : ''}</li>
                        <li>{this.state.change2 ? '不断的提高要求' : ''}</li>
                    </ul>
                </div>
                <div className={this.state.change3 ? 'cultrueboxbig' : 'cultruebox'} onMouseMove={this.aboutchange3}>
                    <div className={this.state.change3 ? 'cultrueheaderbig' : 'cultrueheader'}>
                    高效：
                    </div>
                    <ul>
                        <li>{this.state.change3 ? '高效是我们办事的准则' : ''}</li>
                        <li>{this.state.change3 ? '做必要的沟通' : ''}</li>
                        <li>{this.state.change3 ? '最快的速度响应用户' : ''}</li>
                    </ul>
                </div>
                <div className={this.state.change4 ? 'cultrueboxbig' : 'cultruebox'} onMouseMove={this.aboutchange4}>
                    <div className={this.state.change4 ? 'cultrueheaderbig' : 'cultrueheader'}>
                    创新：
                    </div>
                    <ul>
                        <li>{this.state.change4 ? '始终像创业初期保持创新' : ''}</li>
                        <li>{this.state.change4 ? '接受新事物，走出舒适圈' : ''}</li>
                        <li>{this.state.change4 ? '不怕困难，坚定脚步' : ''}</li>
                    </ul>
                </div>
            </div>
        )
    }
}
