import React, { Component } from 'react'
import Function from './Functional'
import Functiong from './Functionalg'
import Productadvantage from './Productadvantage'
import Applicationscenarios from './Applicationscenarios'
import yqfks from '@assets/疫情防控s.png'
import pexels from '@assets/pexels.png'
import dn from '@assets/电脑.png'
import bsdt from '@assets/办事大厅.png'
import PubSub from 'pubsub-js'
import './index.css'
// import axios from 'axios'

export default class Product extends Component {
    state = {
        Function1:
        {
            h2: '智慧园区',
            p: '助力园区构建一个统一的数据服务平台，实现高效、便捷的集中式管理',
            img: yqfks
        },
        Function2: {
            h2: '智慧景区',
            p: '三维导览、多元化介绍、多种待机模式助力游客游玩体验',
            img: pexels
        },
        Functiong1:{
            h2:'智慧湖泊',
            p:'为环境监管的数据联动、应急事故的指挥调度等提供科学的依据',
            img:dn
        },
        Functiong2:{
            h2:'智慧社区',
            p:'为新型的社区提供一个直观、简化的平台，让工作者人人可用、人人会用、人人好用',
            img:bsdt
        }
    }
    componentWillUnmount() {
        // 卸载异步操作设置状态
        clearTimeout(this.timeouter)
        this.setState = (state, callback) => {
          return
        }
      }
      componentDidMount() {
        this.address()
    }
    address=()=>{
        PubSub.publish('address',{
            address:this.props.location.pathname
        });
    }
    render() {
        return (
            <div className="product">
                <div className="producthead">
                </div>
                <h1 className="producttitle">产品技术</h1>
                <p className="productheadp">开放式智能点位拾取，让操作更简单</p>
                <div className="productbody">
                    <div className="functiontitle">
                        <h1>功能特点</h1>
                    </div>
                    <Function Function={this.state.Function1} />
                    <Functiong Functiong={this.state.Functiong1} />
                    <Function Function={this.state.Function2} />
                    <Functiong Functiong={this.state.Functiong2} />
                    <Productadvantage/>
                    <Applicationscenarios/>
                </div>
            </div>
        )
    }
}
