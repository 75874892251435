import React, { Component } from 'react'
import { Button } from 'antd';
import './index.css'
import { Link } from 'react-router-dom';

export default class Square1 extends Component {
    state=this.props.Square
    render() {
        let button;
        if(this.state.done){
            button=(
                <Link to={this.state.luyou}>
                                <Button type="primary" className="showsbutton">查看详情</Button>
                </Link>

            )
        }else{
            button=('')
        }
        return (
            <div className="Square">
                <div className="Squareimg">
                    <img src={this.state.img} alt=""/>
                </div>
                <img src={require('@assets/案例小标.png').default} alt=""  className="Squareimgsmall"/>
                <div className="Squaretext">
                    <h1>{this.state.h1}</h1>
                    <p>{this.state.p}</p>
                    {button}
                </div>

            </div>
        )
    }
}
