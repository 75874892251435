import React, { Component } from 'react'
import Functional from './FunctionalM'
import Solutionswiper from '../SolutionM/Solutionswiper'
import './index.scss'

export default class ProductM extends Component {
    render() {
        return (
            <div>
                <div className="productmback ">
                </div>
                <h1 className="productmbacktitle">产品技术</h1>
                <div className="productmbody">
                    {/* 应用技术 */}
                    <div className="solutionmtitle"><h1>应用场景</h1></div>
                    <img src={require('@assets/疫情防控.png').default} alt="" />
                    <div className="productmbodytitle"><span></span><p>精细的可视化管理</p></div>
                    <p>平台内置标准的技术、业务、管理、质量属性，结合灵活的定义方式，从手工创建、导入、拾取、智能识别等，可帮助客户快速构建基础标准、指标标准、代码标准</p>
                    <div className="productmbodytitle"><span></span><p>全面的信息展示</p></div>
                    <p>平台内置标准的技术、业务、管理、质量属性，结合灵活的定义方式，从手工创建、导入、拾取、智能识别等，可帮助客户快速构建基础标准、指标标准、代码标准，同时内置的一批国标、行标，可大大提高项目交付效率。</p>
                    <div className="productmbodytitle"><span></span><p>准确的场景还原</p></div>
                    <p>平台内置标准的技术、业务、管理、质量属性，结合灵活的定义方式，从手工创建、导入、拾取、智能识别等，可帮助客户快速构建基础标准、指标标准、代码标准，同时内置的一批国标、行标，可大大提高项目交付效率。</p>
                    <div className="productmbodytitle"><span></span><p>精细的可视化管理</p></div>
                    <p>平台内置标准的技术、业务、管理、质量属性，结合灵活的定义方式，从手工创建、导入、拾取、智能识别等，可帮助客户快速构建基础标准、指标标准、代码标准</p>
                    {/* 功能特点 */}
                    <div className="solutionmtitle"><h1>功能特点</h1></div>
                    <Functional/>
                    <div className="solutionmtitle"><h1>应用场景</h1></div>
                    <Solutionswiper/>
                </div>
            </div>
        )
    }
}
