import React, { Component } from 'react'
import Shuffling from './Shuffling'
import Threebox from './Threebox'
import StarM from './StarM'
import ManyM from './ManyM'
import DynamicM from './DynamicM'
import zu2 from '@assets/组2改.png'
import zu3 from '@assets/组3.png'
import zu4 from '@assets/组4.png'
import zu5 from '@assets/组5.png'
import zu6 from '@assets/组6.png'
import './index.scss'

export default class HomeM extends Component {
    state = {
        box: [{
            title: '3D数字孪生平台架构',
            li1: '·IOT模块接入',
            li2: '·硬件交互控制',
            li3: '·时空场景引用',
            li4: '·原有系统业务接入',
            li5: '·多样化图表展示',
            li6: '·云渲染服务',
            img: zu2
        },
        {
            title: '数字孪生场景精度',
            li1: '·LOD多精度选择',
            li2: '·楼宇场景还原',
            li3: '·高程地形还原',
            li4: '·绿化场景还原',
            li5: '·管网管道还原',
            li6: '·交通路线还原',
            img: zu3
        },
        {
            title: '平台功能特效',
            li1: '·大场景空间表达',
            li2: '·室内空间管理',
            li3: '·资产可视化管理',
            li4: '·安防可视化管理',
            li5: '·多平台数据打通',
            li6: '·事件复盘演练',
            img: zu4
        },
        {
            title: '产品性能',
            li1: '·市级模型体量承载',
            li2: '·远程更新与维护',
            li3: '',
            li4: '·高安全性',
            li5: '·多平台兼容',
            li6: '',
            img: zu5
        },
        {
            title: '平台宗旨',
            li1: '·历史再现/归纳总结/隐患剖析',
            li2: '·远程物联/动态仿真/数字管控',
            li3: '·及时预防/态势模拟/应急预案',
            li4: '',
            li5: '',
            li6: '',
            img: zu6
        },]
    }
    render() {
        return (
            <div className="HomeM">
                {/* 轮播图 */}
                <div className="shufflingall">
                    <Shuffling />
                </div>
                <div className="Mbody">
                    {/* 三维数字 */}
                    <div className="threetitle">
                        <h2>三维数字孪生引擎</h2>
                        <p>致力于成为最优质的GIS平台供应商</p>
                    </div>
                    <Threebox box={this.state.box} />
                    {/* 明星案例 */}
                    <StarM />
                    {/* 多场景解决方案 */}
                    <ManyM />
                    {/* 企业动态 */}
                    <DynamicM />
                </div>

            </div>
        )
    }
}
