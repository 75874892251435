import React, { Component } from 'react'
import {  LoadingOutlined } from '@ant-design/icons';
import './index.css'

export default class Please extends Component {
    render() {
        return (
            <div className="please">
                <h1>暂时没有更多,尽请期待哦!</h1>
                <LoadingOutlined  className="plicon"/>
            </div>
        )
    }
}
