import React, { Component } from 'react'
import { Button } from 'antd';
import { Link } from 'react-router-dom'
import Img1 from '@assets/南浔古镇.png'
import './index.css'


export default class Case1 extends Component {

    state = {
        startitle: ['南浔古镇现状及未来规划蓝图'],
        starli1: ['结合电子大屏与实体沙盘科技化的展示景区理念与未来蓝图'],
        starli2: ['多种待机模式助力游客游玩体验，通过多样数字化素诠释古镇底蕴'],
        starli4: ['智能沙盘'],
        starli5: ['巨幕大屏'],
        starli6: ['文创互动'],
        caseatp: true
    }
    play1 = () => {
        this.setState({ caseatp: !this.state.caseatp })
    }
    render() {
        return (
                <div>
                    <div className="startext">
                        <div className="startextul">
                            <h2>{this.state.startitle}</h2>
                            <ul className="floatleft11">
                                <li>{this.state.starli1}</li>
                                <li>{this.state.starli2}</li>
                            </ul>
                            <ul className="floatleft22">
                                <li>{this.state.starli4}</li>
                                <li>{this.state.starli5}</li>
                                <li>{this.state.starli6}</li>
                            </ul>
                        </div>
                        <Link to='/Nanxuntown' className="starbutton">
                            <Button type="primary" style={{ backgroundColor: '#2468F2', width: '150px' }}>
                                查看详情
                            </Button>
                        </Link>
                        <div className="starimg" >
                            <img src={Img1} alt="" onMouseMove={this.play1} />
                        </div>
                    </div>
                </div>
            )
        }
    }