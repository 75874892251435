import React, { Component } from 'react'
import ldwn from '@assets/量大微脑.png'
import smh from '@assets/四明湖.png'
import czyq from '@assets/承载园区.png'
import nxgz from '@assets/南浔古镇.png'
import CaseM from './CasesM'
import './index.scss'

export default class CaseshowsM extends Component {
    state = {
        case: [
            {
                img: ldwn,
                title: '中国计量大学——量大微脑',
                li1: '数据处理',
                li2: '数据可视',
                li3: '三维场景',
                li4: '天气切换',
                pathm:'/LargegamountofmicrobrainM'
            },
            {
                img: smh,
                title: '四明湖水库智慧水利',
                li1: '数据处理',
                li2: '数据可视',
                li3: '三维场景',
                li4: '天气切换',
                pathm:'/FoursiminghuM'
            },
            {
                img: czyq,
                title: '消防导视平台',
                li1: '数据处理',
                li2: '数据可视',
                li3: '三维场景',
                li4: '天气切换',
                pathm:'/WisdomparkM'
            },
            {
                img: nxgz,
                title: '古镇互动沙盘',
                li1: '数据处理',
                li2: '数据可视',
                li3: '三维场景',
                li4: '天气切换',
                pathm:'/NanxuntownM'
            },
        ]
    }
    render() {
        return (
            <div>
                <div className="caseshowsmback">
                </div>
                <h1 className="caseshowsmbacktitle">案例展示</h1>
                <div className="caseshowsmbody">
                    <CaseM casem={this.state.case}/>
                </div>
            </div>
        )
    }
}
