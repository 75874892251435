import React, { Component } from 'react'
import './index.scss'

export default class CooperationM extends Component {
    render() {
        return (
            this.props.CooperationM.map(Item => {
                return (
                        <div className="CooperationMbox" key={Item.p} style={{ marginLeft: Item.marginleft + '%' }}>
                            <p>{Item.p}</p>
                        </div>
                )
            })
        )
    }
}
