import React, { Component } from 'react'
import { Player, BigPlayButton, ControlBar } from 'video-react';
import { Button } from 'antd';
import Video from '@assets/计量微脑.mp4'
import { Link } from 'react-router-dom'
import Img1 from '@assets/办事大厅概况.png'
import './index.css'


export default class Case1 extends Component {

    state = {
        startitle: ['深层次整合挖掘校园信息化数据'],
        starli1: ['综合平台：实现校园各类大数据的可视化展示和决策管理'],
        starli2: ['三维场景：将校园空间地理信息和业务数据充分融合'],
        starli3: ['角色分布：轻松应对各类人员的数据需求，对应不同数据权限'],
        starli4: ['科学决策'],
        starli5: ['信息管理'],
        starli6: ['信息交换'],
        starli7: ['资源组织'],
        caseatp: true
    }
    play1 = () => {
        this.setState({ caseatp: !this.state.caseatp })
    }
    render() {
        let qqq;
        if (this.state.caseatp) {
            qqq = (
                <div className="starimg" >
                    <img src={Img1} alt="" onMouseMove={this.play1} />
                </div>
            )
        } else {
            qqq = (
                <div className="starimg">
                    <Player
                        playsInline
                        muted={true}
                        // poster='@assets/logoblue.png'
                        src={Video}
                        object-fit='fill'
                        className="startextplayer"
                        autoPlay
                    >
                        <BigPlayButton position="center" disabled />
                        <ControlBar autoHide={false} disableCompletely={true} />
                    </Player>
                </div>
            )
        }
        return (
            <div>
                <div className="startext">
                    <div className="startextul">
                        <h2>{this.state.startitle}</h2>
                        <ul className="floatleft11">
                            <li>{this.state.starli1}</li>
                            <li>{this.state.starli2}</li>
                            <li>{this.state.starli3}</li>
                        </ul>
                        <ul className="floatleft22">
                            <li>{this.state.starli4}</li>
                            <li>{this.state.starli5}</li>
                            <li>{this.state.starli6}</li>
                            <li>{this.state.starli7}</li>
                        </ul>
                    </div>
                    <Link to='/Largegamountofmicrobrain' className="starbutton">
                        <Button type="primary" style={{ backgroundColor: '#2468F2',width:'150px' }}>
                            查看详情
                        </Button>
                    </Link>
                    {qqq}
                </div>
            </div>
        )
    }
}
