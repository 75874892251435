import React, { Component } from 'react'
import CultureM from './CultureM'
import CooperationM from './CooperationM'
import StyleM from './StyleM'
import './index.scss'

export default class AboutM extends Component {
    state = {
        CooperationM1: [
            {
                p: '阿里巴巴',
                marginleft: '0'
            },
            {
                p: '中国移动',
                marginleft: '3.33'
            },
            {
                p: '华数传媒',
                marginleft: '3.33'
            },
            {
                p: '中国电信',
                marginleft: '0'
            },
            {
                p: '商汤科技',
                marginleft: '3.33'
            },
            {
                p: '浙大中控',
                marginleft: '3.33'
            },
            {
                p: '腾讯云平台',
                marginleft: '0'
            },
            {
                p: '吉利汽车',
                marginleft: '3.33'
            },
            {
                p: '杭州亚组委',
                marginleft: '3.33'
            },
            {
                p: '国家电网',
                marginleft: '0'
            },
            {
                p: '诸暨文旅',
                marginleft: '3.33'
            },
            {
                p: '高德地图',
                marginleft: '3.33'
            },
            {
                p: '中国计量大学',
                marginleft: '0'
            },
            {
                p: '杭州师范大学',
                marginleft: '3.33'
            },
            {
                p: '中国民航大学',
                marginleft: '3.33'
            },
        ]
    }
    render() {
        return (
            <div className="aboutm">
                <div className="aboutmback">
                </div>
                <h1 className="aboutmbacktitle">炫眼科技</h1>
                <p className="aboutmbackp">做中国卓越的智能数据产品和服务提供商</p>
                <div className="aboutmbody">
                    <div className="solutionmtitle"><h1>杭州炫眼信息科技有限公司</h1></div>
                    <h2>一家以计算机图形学为基础的中国原创科技公司。</h2>
                    <p className="aboutmbigp">公司横跨物理模拟、工业仿真、人工智能、大数据、云计算等技术领域，为智慧城市、智能制造、智慧景区等行业提供全球领先的数字孪生产品和服务，专业从事AR（增强现实）/VR（虚拟现实）/MR（混合现实）/三维数据可视化管控系统（智慧景区、智慧城市、智慧高校、智慧社区等）定制与开发，核心聚焦于智慧文旅和智慧高校的信息化升级。</p>
                    {/* 企业文化 */}
                    <div className="solutionmtitle"><h1>企业文化</h1></div>
                    <CultureM />
                    {/* 合作企业 */}
                    <div className="solutionmtitle"><h1>合作企业</h1></div>
                    {/* CooperationM样式去CooperationM找 */}
                    <div className="CooperationM">
                        <CooperationM CooperationM={this.state.CooperationM1} />
                    </div>
                    {/* 炫眼荣誉 */}
                    <div className="solutionmtitle"><h1>炫眼荣誉</h1></div>
                    <img src={require('@assets/企业荣誉.png').default} alt="" />
                    {/* 企业风采 */}
                    <div className="solutionmtitle"><h1>企业风采</h1></div>
                    <StyleM />
                    {/* 联系我们 */}
                    <div className="solutionmtitle"><h1>联系我们</h1></div>
                    <div className="usm">
                        <img src={require('@assets/邮箱.png').default} alt="" />
                        <div className="usmh3">
                            <h3>企业邮箱：</h3>
                            <h3>155412355@qq.com</h3>
                        </div>
                    </div>
                    <div className="usm">
                        <img src={require('@assets/电话.png').default} alt="" />
                        <div className="usmh3">
                            <h3>联系电话：</h3>
                            <h3>027-81803001 （张总）  微信同号</h3>
                        </div>
                    </div>
                    <div className="usm">
                        <img src={require('@assets/邮箱.png').default} alt="" />
                        <div className="usmh3">
                            <h3>企业地址：</h3>
                            <h3>浙江省杭州市江干区文海南路海聚中心6栋503</h3>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
