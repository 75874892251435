import React, { Component } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import Img1 from '@assets/动态111.png'
import Img2 from '@assets/动态222.png'
import Img3 from '@assets/动态333.png'
import Img4 from '@assets/动态444.png'
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
import './index.css'
import SwiperCore, { Pagination } from 'swiper/core';
import { Link } from 'react-router-dom';
SwiperCore.use([Pagination]);

export default class Dynamic extends Component {
    state = {
        dynamich1: '“数字孪生城市”来了',
        dynamich2: '数据“活”了，腾讯位置服务推出移动端3D数据可视化图层',
        dynamich3: '百度如流成2021百度世界最惊喜彩蛋 智能会议纪要炸场',
        dynamich4: '连接虚拟与现实的数字桥梁 NVIDIA开启元宇宙的无限想象',
        dynamicp1: '在一座物理世界的实体城市之外，是否还可能存在另一座虚拟世界的数字城市？二者一体同胞，虚实相生，如影随形，同生共长。',
        dynamicp2: '大数据能力在应用端逐渐普及,在保证数据完整性的同时,还要让这些数据能够快速被看懂、被吸收,才能发挥出这些数据的最大价值。',
        dynamicp3: '8月18日，2021百度世界大会召开，展示百度AI的诸项先进成果，科技感满满。',
        dynamicp4: 'NVIDIA是全球图形运算行业和视觉运算行业的领袖，也是GPU的发明者。',
    }
    render() {
        return (
            <div className="dynamic">
                <Swiper slidesPerView={3} spaceBetween={30} pagination={{
                    "clickable": true
                }} className="mySwiper">
                    <SwiperSlide>
                        <div className="dyexternal">
                            <Link to='/ThePartner'>
                                <div className="dynamicimg">
                                    <img src={Img1} alt=""  className=".swiper-slideimg"/>
                                </div>
                            </Link>
                            <div className="slide">
                                <h2>{this.state.dynamich1}</h2>
                                <p>{this.state.dynamicp1}</p>
                                <p className="day">7月6日</p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="dyexternal">
                            <Link to='/ThePartner2'>
                                <div className="dynamicimg">
                                    <img src={Img2} alt="" className=".swiper-slideimg"/>
                                </div>
                            </Link>
                            <div className="slide">
                                <h2>{this.state.dynamich2}</h2>
                                <p>{this.state.dynamicp2}</p>
                                <p className="day">8月18日</p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="dyexternal">
                            <Link to='/ThePartner3'>
                                <div className="dynamicimg">
                                    <img src={Img3} alt="" className=".swiper-slideimg"/>
                                </div>
                            </Link>
                            <div className="slide">
                                <h2>{this.state.dynamich3}</h2>
                                <p>{this.state.dynamicp3}</p>
                                <p className="day">8月19日</p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="dyexternal">
                            <Link to='/ThePartner4'>
                                <div className="dynamicimg">
                                    <img src={Img4} alt="" className=".swiper-slideimg"/>
                                </div>
                            </Link>
                            <div className="slide">
                                <h2>{this.state.dynamich4}</h2>
                                <p>{this.state.dynamicp4}</p>
                                <p className="day">8月19日</p>
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
        )
    }
}
