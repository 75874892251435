import React, { Component } from 'react'
import { Breadcrumb } from 'antd';
import PubSub from 'pubsub-js'
import './index.css'

export default class Largeg extends Component {
    state = {
        largetitle: '中国计量大学——量大微脑',
    }
    search = () => {
        PubSub.publish('shuju', {
            changemenu1: false,
            changemenu2: false,
            changemenu3: true,
            changemenu4: false,
            changemenu5: false,
            changemenu6: false,
        })
    }
    render() {
        return (
            <div className="largebody">
                <Breadcrumb className="Bread" separator='>'>
                    <Breadcrumb.Item href="https://xuanyantech.com/#/TheHome">
                        首页
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href="https://xuanyantech.com/#/Thecaseshows" onClick={this.search}>明星案例推荐</Breadcrumb.Item>
                    <Breadcrumb.Item href="#">{this.state.largetitle}</Breadcrumb.Item>
                </Breadcrumb>
                <div className="largetitle">
                    <h1>{this.state.largetitle}</h1>
                </div>
                <div className="largetxt">
                    <p> 建设理念为深层次整合挖掘校园信息化数据，有效的支持和辅助校园管理，从而实现科学的决策、信息化的管理、无缝的信息交换、合理的资源组织分配等工作。</p>
                    <p>基于三维数字孪生校园通过对校园疫情常态化精准防控系统、安防管理软硬件系统、人车流软硬件系统、网上办事大厅软硬件系统、学校设备资产资源软硬件系统、学生公寓管理软硬件系统、校园实体办事大厅软硬件系统、微脑数据管理平台和空间数据及其属性信息进行采集,将校园空间地理信息(如建筑物外形与轮廓、建筑楼层结构、环境地貌信息、建筑位置布局等)和业务数据充分融合，实现校园各类大数据的可视化展示和决策管理。
                        硕士培养高校第一名，2020年列软科中国大学排名硕士点高校第一。</p>
                    <img src={require('@assets/学生公寓.png').default} alt="" />
                    <img src={require('@assets/办事大厅概况.png').default} alt="" />
                </div>
            </div>
        )
    }
}
