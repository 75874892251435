import React, { Component } from 'react'
import PubSub from 'pubsub-js'
import './index.css'
import { Link } from 'react-router-dom'


export default class Footer extends Component {
    state = {
        mdata1: 'Technologyofqualification'
    }
    search = () => {
        PubSub.publish('shuju', {
            changemenu1: false,
            changemenu2: false,
            changemenu3: false,
            changemenu4: false,
            changemenu5: true,
            changemenu6: false,
        })
    }
    search1 = () => {
        PubSub.publish('shuju', {
            name: 'Technologyofqualification',
            changemenu1: false,
            changemenu2: false,
            changemenu3: false,
            changemenu4: false,
            changemenu5: true,
            changemenu6: false,
        })
    }
    search2 = () => {
        PubSub.publish('shuju', {
            name: 'Cooperationtonegotiate',
            changemenu1: false,
            changemenu2: false,
            changemenu3: false,
            changemenu4: false,
            changemenu5: false,
            changemenu6: true,
        })
    }
    search3 = () => {
        PubSub.publish('shuju', {
            changemenu1: false,
            changemenu2: false,
            changemenu3: false,
            changemenu4: true,
            changemenu5: false,
            changemenu6: false,
        })
    }
    search4 = () => {
        PubSub.publish('shuju', {
            changemenu1: false,
            changemenu2: true,
            changemenu3: false,
            changemenu4: false,
            changemenu5: false,
            changemenu6: false,
        })
    }
    render() {
        return (
            <div className="footer">
                <div className="ftable">
                    <ul>
                        <li style={{ fontSize: 22, marginBottom: 30 }}>解决方案</li>
                        <Link to="/TheSolution" onClick={this.search4}>
                            <li >数字可视化</li>
                        </Link>
                        <li>虚拟仿真</li>
                        <li>AR、VR创意互动</li>
                        <li>其他方案</li>
                    </ul>
                    <ul>
                        <li style={{ fontSize: 22, marginBottom: 30 }}>产品技术</li>
                        <Link to="/TheProduct" onClick={this.search3}>
                            <li>3D数字孪生</li>
                        </Link>
                        {/* <li>编辑器</li>
                        <li>地图模块</li> */}
                    </ul>
                    <ul>
                        <li style={{ fontSize: 22, marginBottom: 30 }}>关于我们</li>
                        <li><a href="/#/TheAbout" onClick={this.search}>公司简介</a></li>
                        <li><a href="/#/TheAbout" onClick={this.search1}>资质技术</a></li>
                        <li><a href="/#/TheAbout" onClick={this.search2}>合作洽谈</a></li>
                    </ul>
                    <div className="logoblue"></div>
                    <div className="bottom">
                        <div className="bottom1">©XUANYANKEJI</div>
                        <div className="bottom2">版权所有©杭州炫眼信息科技有限公司
                            All rights reserved. </div>
                        <div className="bottom3"><a href="https://beian.miit.gov.cn/#/Integrated/index">浙ICP备16031960号-1</a> </div>
                    </div>
                </div>

            </div>
        )
    }
}
