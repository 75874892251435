import React, { Component } from 'react'
import { Button } from 'antd';
import solutionimg from '@assets/计量.png'
import './index.css'
import { Link } from 'react-router-dom';

export default class Solution1 extends Component {
    state = {
        p: '基于三维数字孪生校园通过对校园疫情常态化精准防控系统、安防管理软硬件系统、人车流软硬件系统、网上办事大厅软硬件系统、学校设备资产资源软硬件系统、学生公寓管理软硬件系统、校园实体办事大厅软硬件系统、微脑数据管理平台和空间数据及其属性信息进行采集,将校园空间地理信息和业务数据充分融合，实现校园各类大数据的可视化展示和决策管理。'
    }
    render() {
        let userMessage;
        if (this.props.loggedIn) {
            userMessage = (
                ''
            )
        } else {
            userMessage = (
                <Link to='/Largegamountofmicrobrain'>
                    <Button ghost className="solutionbutton">立即查看</Button>
                </Link>
            )
        }
        return (
            <div>
                <div className="solutioncasetext">
                    <div className="solutioncasetextp">
                        <p>
                            {this.state.p}
                        </p>
                    </div>
                    {userMessage}
                </div>
                <div className="solutionimgdivs">
                    <div className="solutionimgdiv">
                        <img src={solutionimg} alt="" className="solutionimg" />
                    </div>
                </div>
            </div>

        )
    }
}
