import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export default class Partner extends Component {
    state = {
        DynamicM1:
        {
            title: '“数字孪生城市”来了',
            p: '在一座物理世界的实体城市之外，是否还可能存在另一座虚拟世界的数字城市？',
            day: '7月6日'
        }
        ,
        DynamicM2:
        {
            title: '数据“活”了，腾讯位置服务推出移动端3D数据可视化图层',
            p: '大数据能力在应用端逐渐普及,在保证数据完整性的同时……',
            day: '8月18日'
        }
        ,
        DynamicM3:
        {
            title: '百度如流成2021百度世界最惊喜彩蛋 智能会议纪要炸场',
            p: '8月18日，2021百度世界大会召开，展示百度AI的诸项先进成果……',
            day: '8月19日'
        }
        ,
        DynamicM4:
        {
            title: '连接虚拟与现实的数字桥梁 NVIDIA开启元宇宙的无限想象',
            p: 'NVIDIA是全球图形运算行业和视觉运算行业的领袖，也是GPU的发明者。',
            day: '8月19日'
        }
        ,
    }
    render() {
        return (
            <div>
                <div className="largegmbody">
                    <div className="largegmtop">
                        <div className="backm" onClick={() => this.props.history.goBack()}>
                            <img src={require('@assets/路径.png').default} alt="" />
                            <span>返回</span>
                        </div>
                    </div>
                    <h1 style={{ fontWeight: 'bold' }}>数据“活”了，腾讯位置服务推出移动端3D数据可视化图层</h1>
                    <p style={{ color: '#787878', fontSize: '10px', textIndent: '0' }}>发布日期 2021/08/18</p>
                    <img src={require('@assets/2000.jpg').default} style={{ marginBottom: '30px', marginTop: '10px' }} alt="" />
                    <p>大数据能力在应用端逐渐普及,在保证数据完整性的同时,还要让这些数据能够快速被看懂、被吸收,才能发挥出这些数据的最大价值。近期,腾讯位置服务于业内率先发布地图SDK 3D数据可视化模块,基于OpenGL ES 3.0打造,继承Native引擎的渲染能力优势的可视化模块,可以充分发挥移动端GPU的并行计算能力,让移动端开发者不再受限于H5的性能短板,充分享受高性能海量数据渲染优势,将地理空间数据和地图完美结合。</p>
                    <p>
                        此前,腾讯位置服务面向Web端推出了数据可视化API,此次移动端可视化模块的发布,向“多端一体”、“一图胜千言”的空间数据可视化展示又迈进了一步。据了解,该可视化模块能向开发者提供多种数据可视化演示,包括全新的矢量热力图、蜂窝热力图、弧线图、轨迹图、散点图,覆盖多种数据类型,用于搭配不同的业务数据进行可视化展现。
                    </p>
                    <h3>3D立体动效展示,让数据更直观</h3>
                    <p>在每类图层中,腾讯位置服务都赋予了开发者丰富的属性配置,以打造炫酷多样的数据可视化效果。首先,可视化模块内的所有数据图层样式都支持3D效果展示,结合地图的俯仰视角变化,让用户的感受更加直观、立体。其次,辐射半径、聚合半径、数据区间、展示级别等参数的开放,让开发者即使面对多变的数据规模和分布,都有合理的呈现。最后,腾讯位置服务还提供了多种专业配色风格和对应的参数模板,开发者可以直接上手使用,为用户呈现全新的地图数据可视化视觉体验。</p>
                    <p>除了基础的静态绘制能力之外,腾讯位置服务的这套数据可视化模块还提供了图形的动效显示能力。例如在人口迁徙场景中,可以通过弧线的动态流向,来表现人口迁徙的方向。又比如在公园游客分布场景中,可以加入不同高度的动态拔起效果,来体现人流热力数据按时间变化的情况。还有散点图层的辐射动效,轨迹图的脉冲动效等等,这些丰富的动态效果让数据更加鲜活。
                    </p>
                    <h3>性能强化,细节体验自然流畅</h3>
                    <p>除了功能性的突破,腾讯位置服务也十分重视视觉体验,为了打造细腻逼真的视觉体验,在可视化图层的各种细节上进行精心打磨。
                    </p>
                    <p>腾讯位置服务在性能上也进行了优化升级,摒弃业内常用的瓦片方式呈现热力图效果,而是采用矢量方式,并且在多个层面都进行了效果升级,充分发挥GPU的并行计算能力,同时结合实例化渲染技术,大幅度提升了渲染性能,最高支持百万级热力点和万级弧线在移动端下的流畅操作,大大提升了使用过程中的流畅自然性。
                    </p>
                    <p>目前,腾讯位置服务移动端3D数据可视化模块1.0版本已经集成于腾讯地图SDK中,并可以在腾讯位置服务官网免费下载。未来腾讯位置服务会持续更多更优质的产品,为开发者们带来更实用、更便利的功能和服务。</p>
                    <p>来源：互联网</p>
                    <h3>注明：本公众号转载文章仅用于分享，不用于任何商业用途。如涉及版权问题，敬请后台联络授权或议定合作，我们会按照版权法规定第一时间为您妥善处理。</h3>
                </div>
                <div className="separatedm"></div>
                <div className="DynamicM" style={{ margin: 'auto' }}>
                    <div className="solutionmtitle"><h1>其他案例推荐</h1></div>
                {/* 第一个动态 */}
                <Link to="/ThePartnerM">
                    <div className="DynamicMbox">
                        <img src={require('@assets/动态1.png').default} alt="" />
                        <div className="DynamicMtxt">
                            <h2>{this.state.DynamicM1.title}</h2>
                            <span>{this.state.DynamicM1.day}</span>
                            <p>{this.state.DynamicM1.p}</p>
                        </div>
                    </div>
                </Link>
                    {/* 第三个动态 */}
                    <Link to="/ThePartnerM3">
                        <div className="DynamicMbox">
                            <img src={require('@assets/动态3.png').default} alt="" />
                            <div className="DynamicMtxt">
                                <h2>{this.state.DynamicM3.title}</h2>
                                <span>{this.state.DynamicM3.day}</span>
                                <p>{this.state.DynamicM3.p}</p>
                            </div>
                        </div>
                    </Link>
                    <Link to="/ThePartnerM4">
                        <div className="DynamicMbox">
                            <img src={require('@assets/动态444.png').default} alt="" />
                            <div className="DynamicMtxt">
                                <h2>{this.state.DynamicM4.title}</h2>
                                <span>{this.state.DynamicM4.day}</span>
                                <p>{this.state.DynamicM4.p}</p>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>


        )
    }
}
