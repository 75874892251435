import React, { Component } from 'react'
import Square1 from './Square1'
import Square2 from './Square2'
import yqfk from '@assets/疫情防控.png'
import smh from '@assets/四明湖.png'
import czyq from '@assets/承载园区.png'
import nygz from '@assets/南浔古镇.png'
import PubSub from 'pubsub-js'
import './index.css'

export default class Caseshows extends Component {
    state = {
        Square1: 
            {
                img: yqfk,
                h1: '中国计量大学——量大微脑',
                p: '打通校园数据通道使整个平台具有活力',
                done:true,
                luyou:'/Largegamountofmicrobrain'
            },
            Square2: 
            {
                img: czyq,
                h1: '智慧园区安防综管平台',
                p: '园区综合安防展示的可视化决策辅助平台',
                done:true,
                luyou:'/Wisdompark'
            },
            // Square3: 
            // {
            //     img: czyq,
            //     h1: '四名湖水库智慧水利',
            //     p: '轻松应对各类人员的数据需求，对应不同数据权限',
            //     done:false
            // },
            // 右边放图片样式数据
            Squareg1: {
                img: smh,
                h1: '四名湖水库智慧水利',
                p: '轻松应对各类人员的数据需求，对应不同数据权限',
                done:true,
                luyou:'/SimingLakeReservoir'
            },
            Squareg2: {
                img: nygz,
                h1: '南浔古镇数字沙盘',
                p: '南浔古镇现状及未来规划蓝图',
                done:true,
                luyou:'/Nanxuntown'
            },
    }
    componentDidMount() {
        this.address()
    }
    address=()=>{
        PubSub.publish('address',{
            address:this.props.location.pathname
        });
    }
    render() {
        return (
            <div className="shows">
                <div className="showsimg">
                </div>
                <div className="showsblack"></div>
                <div className="showstitle">
                    <h1 style={{ color: 'white' }}>案例展示</h1>
                </div>
                <div className="showsbody">
                    <Square1 Square={this.state.Square1} />
                    <Square2 Squareg={this.state.Squareg1}/>
                    <Square1 Square={this.state.Square2} />
                    <Square2 Squareg={this.state.Squareg2}/>
                    {/* <Square1 Square={this.state.Square3} /> */}
                </div>
            </div>
        )
    }
}
