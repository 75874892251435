import React, { Component } from 'react'
import './index.scss'

export default class FunctionalmM extends Component {
    state = {
        FunctionalMbox1: true,
        FunctionalMbox2: false,
        FunctionalMbox3: false,
        FunctionalMbox4: false,
    }
    FunctionalMbox1 = () => {
        this.setState({
            FunctionalMbox1: true,
            FunctionalMbox2: false,
            FunctionalMbox3: false,
            FunctionalMbox4: false,
        })
    }
    FunctionalMbox2 = () => {
        this.setState({
            FunctionalMbox1: false,
            FunctionalMbox2: true,
            FunctionalMbox3: false,
            FunctionalMbox4: false,
        })
    }
    FunctionalMbox3 = () => {
        this.setState({
            FunctionalMbox1: false,
            FunctionalMbox2: false,
            FunctionalMbox3: true,
            FunctionalMbox4: false,
        })
    }
    FunctionalMbox4 = () => {
        this.setState({
            FunctionalMbox1: false,
            FunctionalMbox2: false,
            FunctionalMbox3: false,
            FunctionalMbox4: true,
        })
    }
    render() {
        let FunctionalMboxtxt1;
        if (this.state.FunctionalMbox1) {
            FunctionalMboxtxt1 = (
                <div className="FunctionalMbutton">
                    <h1>了解详情</h1>
                    <img src={require('@assets/箭头.png').default} alt="" />
                </div>
            )
        } else {
            FunctionalMboxtxt1 = (
                <img src={require('@assets/立体图1.png').default} alt="" className="ltt" style={{width:'80%',marginTop:'-20%'}}/>
            )
        }
        let FunctionalMboxtxt2;
        if (this.state.FunctionalMbox2) {
            FunctionalMboxtxt2 = (
                <div className="FunctionalMbutton">
                    <h1>了解详情</h1>
                    <img src={require('@assets/箭头.png').default} alt="" />
                </div>
            )
        } else {
            FunctionalMboxtxt2 = (
                <img src={require('@assets/立体图2.png').default} alt="" className="ltt"/>
            )
        }
        let FunctionalMboxtxt3;
        if (this.state.FunctionalMbox3) {
            FunctionalMboxtxt3 = (
                <div className="FunctionalMbutton">
                    <h1>了解详情</h1>
                    <img src={require('@assets/箭头.png').default} alt="" />
                </div>
            )
        } else {
            FunctionalMboxtxt3 = (
                <img src={require('@assets/立体图3.png').default} alt="" className="ltt" style={{width:'80%',marginTop:'-12%'}}/>
            )
        }
        let FunctionalMboxtxt4;
        if (this.state.FunctionalMbox4) {
            FunctionalMboxtxt4 = (
                <div className="FunctionalMbutton">
                    <h1>了解详情</h1>
                    <img src={require('@assets/箭头.png').default} alt="" />
                </div>
            )
        } else {
            FunctionalMboxtxt4 = (
                <img src={require('@assets/立体图4.png').default} alt="" className="ltt"/>
            )
        }
        return (
            <div className="FunctionalMboxbody">
                <div className={this.state.FunctionalMbox1 ? 'FunctionalMbox' : 'FunctionalMboxg'} onClick={this.FunctionalMbox1}>
                    <div className="FunctionalMboxtxt">
                        <h1>全生命周期标准管理</h1>
                        <p>·IOT模块接入</p>
                        <p>·硬件交互控制</p>
                        <p>·时空场景引用</p>
                    </div>
                    <div className="FunctionalMboximg">
                        {FunctionalMboxtxt1}
                    </div>
                </div>
                <div className={this.state.FunctionalMbox2 ? 'FunctionalMbox' : 'FunctionalMboxg'} onClick={this.FunctionalMbox2} style={{ marginLeft: '10%' }}>
                    <div className="FunctionalMboxtxt">
                        <h1>全生命周期标准管理</h1>
                        <p>·IOT模块接入</p>
                        <p>·硬件交互控制</p>
                        <p>·时空场景引用</p>
                    </div>
                    {FunctionalMboxtxt2}
                </div>
                <div className={this.state.FunctionalMbox3 ? 'FunctionalMbox' : 'FunctionalMboxg'} onClick={this.FunctionalMbox3} style={{ marginTop: '35px' }}>
                    <div className="FunctionalMboxtxt">
                        <h1>全生命周期标准管理</h1>
                        <p>·IOT模块接入</p>
                        <p>·硬件交互控制</p>
                        <p>·时空场景引用</p>
                    </div>
                    {FunctionalMboxtxt3}
                </div>
                <div className={this.state.FunctionalMbox4 ? 'FunctionalMbox' : 'FunctionalMboxg'} onClick={this.FunctionalMbox4} style={{ marginTop: '35px', marginLeft: '10%' }}>
                    <div className="FunctionalMboxtxt">
                        <h1>全生命周期标准管理</h1>
                        <p>·IOT模块接入</p>
                        <p>·硬件交互控制</p>
                        <p>·时空场景引用</p>
                    </div>
                    {FunctionalMboxtxt4}
                </div>
            </div>
        )
    }
}
