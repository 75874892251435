import React, { Component } from 'react'
import './index.scss'

export default class AdvantagesM extends Component {
    render() {
        return (
            this.props.jsys.map(item => {
                return (
                    <div className="AdvantagesM" key={item.title} style={{marginRight:item.right + '%'}}>
                        <img src={item.img} alt="" />
                        <h1>{item.title}</h1>
                        <p>{item.p}</p>
                    </div>
                )
            })

        )
    }
}
