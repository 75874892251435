import React, { Component } from 'react'
import { Breadcrumb } from 'antd';
import PubSub from 'pubsub-js'


export default class Largeg extends Component {
    state = {
        largetitle: '南浔古镇数字沙盘',
    }
    search = () => {
        PubSub.publish('shuju', {
            changemenu1: false,
            changemenu2: false,
            changemenu3: true,
            changemenu4: false,
            changemenu5: false,
            changemenu6: false,
        })
    }
    render() {
        return (
            <div className="largebody">
                <Breadcrumb className="Bread" separator='>'>
                    <Breadcrumb.Item href="https://xuanyantech.com/#/TheHome">
                        首页
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href="https://xuanyantech.com/#/Thecaseshows" onClick={this.search}>明星案例推荐</Breadcrumb.Item>
                    <Breadcrumb.Item href="#">{this.state.largetitle}</Breadcrumb.Item>
                </Breadcrumb>
                <div className="largetitle">
                    <h1>{this.state.largetitle}</h1>
                </div>
                <div className="largetxt">
                    <p>全面体现南浔古镇现状及未来规划蓝图，助力参观者景区信息导览，结合电子大屏与实体沙盘科技化的展示景区目前规划的理念与未来蓝图、三维导览、多元化介绍、多种待机模式助力游客游玩体验，通过多样数字化素诠释古镇底蕴。</p>
                    <p>一个控制核心三个展示平台：智能沙盘、巨幕大屏、文创互动、软硬件平台打通、实体沙盘互动、声光平台控制。</p>
                    <img src={require('@assets/南浔古镇.png').default} alt="" />
                </div>
            </div>
        )
    }
}
