import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import './index.css'

export default class Othertext extends Component {
    state = {

    }
    render() {
        return (
            <div>
                <div className="other">
                    <Link to='/ThePartner'>
                        <div className="otherimg">
                            <img src={require('@assets/动态111.png').default} alt="" />
                        </div>
                    </Link>
                    <h2>“数字孪生城市”来了{this.state.otherh2}</h2>
                    <p>在一座物理世界的实体城市之外，是否还可能存在另一座虚拟世界的数字城市？二者一体同胞，虚实相生，如影随形，同生共长。{this.state.otherp}</p>
                    <span>7月06日</span>
                </div>
                <div className="other">
                    <Link to='/ThePartner2'>
                        <div className="otherimg">
                            <img src={require('@assets/动态222.png').default} alt="" />
                        </div>
                    </Link>
                    <h2>数据“活”了，腾讯位置服务推出移动端3D数据可视化图层{this.state.otherh2}</h2>
                    <p>据说SuperAPI又双叒更新啦！这次会带来哪些新功能，又有哪些高阶玩法？{this.state.otherp}</p>
                    <span>8月18日</span>
                </div>
                <div className="other" style={{ marginRight: '0' }}>
                    <Link to='/ThePartner4'>
                        <div className="otherimg">
                            <img src={require('@assets/动态444.png').default} alt="" />
                        </div>
                    </Link>
                    <h2>连接虚拟与现实的数字桥梁 NVIDIA开启元宇宙的无限想象{this.state.otherh2}</h2>
                    <p>NVIDIA是全球图形运算行业和视觉运算行业的领袖，也是GPU的发明者。{this.state.otherp}</p>
                    <span>8月19日</span>
                </div>
            </div>
        )
    }
}
