import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import './index.css'

export default class Othertext extends Component {
    state = {

    }
    render() {
        return (
            <div>
                <div className="other">
                    <Link to='/ThePartner'>
                        <div className="otherimg">
                            <img src={require('@assets/动态111.png').default} alt="" />
                        </div>
                    </Link>
                    <h2>“数字孪生城市”来了{this.state.otherh2}</h2>
                    <p>在一座物理世界的实体城市之外，是否还可能存在另一座虚拟世界的数字城市？二者一体同胞，虚实相生，如影随形，同生共长。{this.state.otherp}</p>
                    <span>7月06日</span>
                </div>
                <div className="other">
                    <Link to='/ThePartner3'>
                        <div className="otherimg">
                            <img src={require('@assets/动态333.png').default} alt="" />
                        </div>
                    </Link>
                    <h2>百度如流成2021百度世界最惊喜彩蛋 智能会议纪要炸场{this.state.otheh2}</h2>
                    <p>“3D+AI”跳水辅助训练系统，精准量化评估动作;新疆国网电力AI巡检，改善工作环境拉高工作效率;丽江城市大脑，主动出击给游客最优质的体验……{this.state.otherp}</p>
                    <span>8月19日</span>
                </div>
                <div className="other" style={{ marginRight: '0' }}>
                    <Link to='/ThePartner4'>
                        <div className="otherimg">
                            <img src={require('@assets/动态444.png').default} alt="" />
                        </div>
                    </Link>
                    <h2>连接虚拟与现实的数字桥梁 NVIDIA开启元宇宙的无限想象{this.state.otherh2}</h2>
                    <p>NVIDIA是全球图形运算行业和视觉运算行业的领袖，也是GPU的发明者。{this.state.otherp}</p>
                    <span>8月19日</span>
                </div>
            </div>
        )
    }
}
