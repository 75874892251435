import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export default class Partner extends Component {
    state = {
        DynamicM1:
        {
            title: '“数字孪生城市”来了',
            p: '在一座物理世界的实体城市之外，是否还可能存在另一座虚拟世界的数字城市？',
            day: '7月6日'
        }
        ,
        DynamicM2:
        {
            title: '数据“活”了，腾讯位置服务推出移动端3D数据可视化图层',
            p: '大数据能力在应用端逐渐普及,在保证数据完整性的同时……',
            day: '8月18日'
        }
        ,
        DynamicM3:
        {
            title: '百度如流成2021百度世界最惊喜彩蛋 智能会议纪要炸场',
            p: '8月18日，2021百度世界大会召开，展示百度AI的诸项先进成果……',
            day: '8月19日'
        }
        ,
        DynamicM4:
        {
            title: '连接虚拟与现实的数字桥梁 NVIDIA开启元宇宙的无限想象',
            p: 'NVIDIA是全球图形运算行业和视觉运算行业的领袖，也是GPU的发明者。',
            day: '8月19日'
        }
        ,
    }
    render() {
        return (
            <div>
                <div className="largegmbody">
                    <div className="largegmtop">
                        <div className="backm" onClick={() => this.props.history.goBack()}>
                            <img src={require('@assets/路径.png').default} alt="" />
                            <span>返回</span>
                        </div>
                    </div>
                    <h1 style={{ fontWeight: 'bold' }}>百度如流成2021百度世界最惊喜彩蛋 智能会议纪要炸场</h1>
                    <p style={{ color: '#787878', fontSize: '10px', textIndent: '0' }}>发布日期 2021/08/19/14:52</p>
                    <img src={require('@assets/3000.jpg').default} style={{ marginBottom: '30px' }} alt="" />
                    <p>“3D+AI”跳水辅助训练系统，精准量化评估动作;新疆国网电力AI巡检，改善工作环境拉高工作效率;丽江城市大脑，主动出击给游客最优质的体验……人工智能的落地开花，让我们的生活更加美好。8月18日，2021百度世界大会召开，展示百度AI的诸项先进成果，科技感满满。新一代智能工作平台如流也在会上大显身手，全程“听音辨义”记录会议要点，同时智能抓取重要信息，生动展现了百度AI在日常工作场景中的应用。</p>
                    <p>
                        目前以人工智能为代表的新一代信息技术蓬勃发展，驱动新一轮科技革命和产业变革。AI的价值并不局限于业务的生产环节，对于企业组织成长进化也在发挥着积极的作用，具体表现在企业沟通协同、创新提效、员工成长进步等各个方面。
                    </p>
                    <p>不仅如此，百度如流的智能会议功能还支持会前智能预约、会中智能会议纪要、会后自动生成待办等，这些功能的实现，将员工从繁复冗杂的工作解放出来，大幅提升了办公效率，让他们能够将注意力集中到更有价值的工作当中。</p>
                    <p>事实上，广受好评的智能会议功能正是基于如流“AI+知识管理”的产品理念所设计出的，它极大地帮助了企业实现创新提效，同时，知识的创造、沉淀、流动、应用也将助力员工个人技能的飞速提升，最终实现员工个人进步与企业发展的双赢。
                    </p>
                    <p>未来已来，人工智能的持续快速发展，成为了推动经济高质量发展的重要引擎，企业的发展更离不开办公组织的革新。百度如流能够帮助各企业行业实现工作的智能化升级，打造智能时代的核心竞争力，期待能够携手更多合作伙伴，拥抱科技，实现合作共赢。
                    </p>
                    <h3>注明：本公众号转载文章仅用于分享，不用于任何商业用途。如涉及版权问题，敬请后台联络授权或议定合作，我们会按照版权法规定第一时间为您妥善处理。</h3>
                </div>
                <div className="separatedm"></div>
                <div className="DynamicM" style={{ margin: 'auto' }}>
                    <div className="solutionmtitle"><h1>其他案例推荐</h1></div>
                    {/* 第一个动态 */}
                    <Link to="/ThePartnerM">
                        <div className="DynamicMbox">
                            <img src={require('@assets/动态1.png').default} alt="" />
                            <div className="DynamicMtxt">
                                <h2>{this.state.DynamicM1.title}</h2>
                                <span>{this.state.DynamicM1.day}</span>
                                <p>{this.state.DynamicM1.p}</p>
                            </div>
                        </div>
                    </Link>
                    {/* 第二个动态 */}
                    <Link to="/ThePartnerM2">
                        <div className="DynamicMbox">
                            <img src={require('@assets/动态2.png').default} alt="" />
                            <div className="DynamicMtxt">
                                <h2>{this.state.DynamicM2.title}</h2>
                                <span>{this.state.DynamicM2.day}</span>
                                <p>{this.state.DynamicM2.p}</p>
                            </div>
                        </div>
                    </Link>
                    <Link to="/ThePartnerM4">
                        <div className="DynamicMbox">
                            <img src={require('@assets/动态444.png').default} alt="" />
                            <div className="DynamicMtxt">
                                <h2>{this.state.DynamicM4.title}</h2>
                                <span>{this.state.DynamicM4.day}</span>
                                <p>{this.state.DynamicM4.p}</p>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
        )
    }
}
