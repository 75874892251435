import React, { Component } from 'react'

import './index.scss'

export default class Threebox extends Component {
    render() {
        return (
            this.props.box.map(
                item => {
                    return (
                        <div className="threebox" key={item.title}>
                            <img src={item.img} alt="" />
                            <div className="threetxt">
                                <h4>{item.title}</h4>
                                <ul>
                                    <li>{item.li1}</li>
                                    <li>{item.li2}</li>
                                    <li>{item.li3}</li>
                                </ul>
                                <ul>
                                    <li style={{ marginLeft: '-10px' }}>{item.li4}</li>
                                    <li style={{ marginLeft: '-10px' }}>{item.li5}</li>
                                    <li style={{ marginLeft: '-10px' }}>{item.li6}</li>
                                </ul>
                            </div>
                        </div>
                    )
                }
            )
        )
    }
}
