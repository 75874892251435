import { Button } from 'antd';
import React, { Component } from 'react'
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles

import "./index.scss";

// import Swiper core and required modules
import SwiperCore, { Pagination } from "swiper";
import { Link } from 'react-router-dom';

// install Swiper modules
SwiperCore.use([Pagination]);


export default class  StarM extends Component { 
       state = {
        title1: '中国计量大学—量大微脑',
        title2: '四明湖水库智慧水利',
        title3: '智慧园区安防综管平台',
        title4: '南浔古镇数字沙盘',
        li1:'科学决策',
        li2:'信息管理',
        li3:'信息交换',
        li4:'资源组织',
        li5:'统计分析',
        li6:'监测报警',
        li7:'安防监控',
        li8:'决策平台',
        li9:'调度管理',
        li10:'数据决策',
        li11:'智能沙盘',
        li12:'巨幕大屏',
        li13:'文创互动',
    }
    render() {
        return (
            <div className="starm">
                <div className="starmtitle">
                    <h1>明星案例推荐</h1>
                </div>
                <Swiper
                    effect={"cards"}
                    grabCursor={true} className="mySwiper"
                    style={{ width: '100%' }}
                    slidesPerView={"auto"}
                    centeredSlides={true}
                    spaceBetween={10}
                    pagination={{
                        clickable: true,
                    }}
                >
                    <SwiperSlide style={{ width: '85%' }}>
                        <div className="starmbox">
                            <div className="starmcolor">
                                <div className="starmboximg"><img src={require('@assets/疫情防控m.png').default} alt="" /></div>
                                <h1>{this.state.title1}</h1>
                                <ul>
                                    <li style={{ marginLeft: '-30px' }}>{this.state.li1}</li>
                                    <li>{this.state.li2}</li>
                                    <li>{this.state.li3}</li>
                                    <li>{this.state.li4}</li>
                                </ul>
                                <p>·综合平台：打通学校数据通道使整个平台具有活力</p>
                                <p>·三维场景：将校园空间地理信息和业务数据充分融合</p>
                                <p>·角色分布：轻松应对各类人员的数据需求，对应不同数据权限</p>
                                <Link to='/LargegamountofmicrobrainM'>
                                <Button type="primary" shape="round" className="starmbutton">
                                    查看详情
                                </Button>
                                </Link>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide style={{ width: '85%' }}>
                        <div className="starmbox">
                            <div className="starmcolor">
                                <div className="starmboximg"><img src={require('@assets/疫情防控m.png').default} alt="" /></div>
                                <h1>{this.state.title2}</h1>
                                <ul>
                                    <li style={{ marginLeft: '-30px' }}>{this.state.li5}</li>
                                    <li>{this.state.li6}</li>
                                    <li>{this.state.li7}</li>
                                </ul>
                                <p>·帮助管理和决策者掌握水库环境资源的布局分布</p>
                                <p>·为环境监管的数据联动、应急事故的指挥调度等提供科学的依据</p>
                                <Link to='/FoursiminghuM'>
                                <Button type="primary" shape="round" className="starmbutton">
                                    查看详情
                                </Button>
                                </Link>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide style={{ width: '85%' }}>
                        <div className="starmbox">
                            <div className="starmcolor">
                                <div className="starmboximg"><img src={require('@assets/疫情防控m.png').default} alt="" /></div>
                                <h1>{this.state.title3}</h1>
                                <ul>
                                    <li style={{ marginLeft: '-30px' }}>{this.state.li8}</li>
                                    <li>{this.state.li9}</li>
                                    <li>{this.state.li10}</li>
                                </ul>
                                <p>·平台以三维场景为载体对关键数据进行综合展示、挖掘分析、应用</p>
                                <p>·多个维度进行日常运行监测、管理、预判，以及突发事件的应急指挥调度管理</p>
                                <p>·为园区管理者提高园区安防管理效率，提供数据决策支撑</p>
                                <Link to='/WisdomparkM'>
                                <Button type="primary" shape="round" className="starmbutton">
                                    查看详情
                                </Button>
                                </Link>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide style={{ width: '85%' }}>
                        <div className="starmbox">
                            <div className="starmcolor">
                                <div className="starmboximg"><img src={require('@assets/疫情防控m.png').default} alt="" /></div>
                                <h1>中国计量大学——量大微脑</h1>
                                <ul>
                                    <li style={{ marginLeft: '-30px' }}>{this.state.li11}</li>
                                    <li>{this.state.li12}</li>
                                    <li>{this.state.li13}</li>
                                </ul>
                                <p>·结合电子大屏与实体沙盘科技化的展示景区理念与未来蓝图</p>
                                <p>·多种待机模式助力游客游玩体验，通过多样数字化素诠释古镇底蕴</p>
                                <Link to='/NanxuntownM'>
                                <Button type="primary" shape="round" className="starmbutton">
                                    查看详情
                                </Button>
                                </Link>
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
        )
    }
}



