import React, { Component } from 'react'
import { Accordion, List } from 'antd-mobile';
import 'antd-mobile/dist/antd-mobile.css';
import { Link } from 'react-router-dom';
import './index.scss'


export default class Jjfa extends Component {
    render() {
        return (
            <div className="Jjfa">
                <Accordion defaultActiveKey="0" className="my-accordion" onChange={this.onChange}>
                    <Accordion.Panel header="解决方案">
                        <List className="my-list">
                            <List.Item style={{ marginTop: '10pt' }}><Link to="/TheSolutionM">数字可视化</Link></List.Item>
                            <List.Item>虚拟仿真</List.Item>
                            <List.Item>AR、VR创意互动</List.Item>
                            <List.Item>其他方案</List.Item>
                        </List>
                    </Accordion.Panel>
                </Accordion>
            </div>
        )
    }
}
