import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PubSub from 'pubsub-js'
import './index.css'

export default class Productadvantage extends Component {
    state = {
        bluestate1: true,
        bluestate2: false
    }
    changeblue1 = () => {
        this.setState({
            bluestate1: true,
            bluestate2: false,
            bluestate3: false,
            bluestate4: false,
        })
    }
    changeblue2 = () => {
        this.setState({
            bluestate1: false,
            bluestate2: true,
            bluestate3: false,
            bluestate4: false,
        })
    }
    changeblue3 = () => {
        this.setState({
            bluestate1: false,
            bluestate2: false,
            bluestate3: true,
            bluestate4: false,
        })
    }
    changeblue4 = () => {
        this.setState({
            bluestate1: false,
            bluestate2: false,
            bluestate3: false,
            bluestate4: true,
        })
    }
    search = () => {
        PubSub.publish('shuju', {
            changemenu1: false,
            changemenu2: false,
            changemenu3: false,
            changemenu4: false,
            changemenu5: true,
            changemenu6: false,
        })
    }
    render() {
        let bluebutton1;
        if (this.state.bluestate1) {
            bluebutton1 = (
                <Link to="/TheAbout" onClick={this.search}>
                    <div className="bluebutton">
                        <h3>了解咨询</h3>
                        <img src={require('@assets/箭头.png').default} alt="" />
                    </div>
                </Link>

            )
        } else {
            bluebutton1 = (
                <img src={require('@assets/立体图1.png').default} alt="" />
            )
        }
        // 判断第二个小图的按钮显示
        let bluebutton2;
        if (this.state.bluestate2) {
            bluebutton2 = (
                <Link to="/TheAbout" onClick={this.search}>
                    <div className="bluebutton">
                        <h3>了解咨询</h3>
                        <img src={require('@assets/箭头.png').default} alt="" />
                    </div>
                </Link>
            )
        } else {
            bluebutton2 = (
                <img src={require('@assets/立体图2.png').default} alt="" />
            )
        }
        // 判断第三个小图的按钮显示
        let bluebutton3;
        if (this.state.bluestate3) {
            bluebutton3 = (
                <Link to="/TheAbout" onClick={this.search}>
                    <div className="bluebutton">
                        <h3>了解咨询</h3>
                        <img src={require('@assets/箭头.png').default} alt="" />
                    </div>
                </Link>
            )
        } else {
            bluebutton3 = (
                <img src={require('@assets/立体图3.png').default} alt="" />
            )
        }
        // 判断第四个小图的按钮显示
        let bluebutton4;
        if (this.state.bluestate4) {
            bluebutton4 = (
                <Link to="/TheAbout" onClick={this.search}>
                    <div className="bluebutton">
                        <h3>了解咨询</h3>
                        <img src={require('@assets/箭头.png').default} alt="" />
                    </div>
                </Link>
            )
        } else {
            bluebutton4 = (
                <img src={require('@assets/立体图4.png').default} alt="" />
            )
        }
        return (
            <div className="pdat">
                <div className="pdattitle"><h2>产品优势</h2></div>
                <div className="padtbody">
                    <div className={this.state.bluestate1 ? 'bluebig' : 'bluesmall'} onMouseMove={this.changeblue1}>
                        <h3>全生命周期标准管理</h3>
                        <div className="blueul">
                            <p>·IOT模块接入</p>
                            <p>·硬件交互控制</p>
                            <p>·时空场景引用</p>
                        </div>
                        {bluebutton1}
                    </div>
                    <div className={this.state.bluestate2 ? 'bluebig' : 'bluesmall'} onMouseMove={this.changeblue2}>
                        <h3>全生命周期标准管理</h3>
                        <div className="blueul">
                            <p>·IOT模块接入</p>
                            <p>·硬件交互控制</p>
                            <p>·时空场景引用</p>
                        </div>
                        {bluebutton2}
                    </div>
                    <div className={this.state.bluestate3 ? 'bluebig' : 'bluesmall'} onMouseMove={this.changeblue3}>
                        <h3>全生命周期标准管理</h3>
                        <div className="blueul">
                            <p>·IOT模块接入</p>
                            <p>·硬件交互控制</p>
                            <p>·时空场景引用</p>
                        </div>
                        {bluebutton3}
                    </div>
                    <div className={this.state.bluestate4 ? 'bluebig' : 'bluesmall'} style={{ marginRight: '0' }} onMouseMove={this.changeblue4}>
                        <h3>全生命周期标准管理</h3>
                        <div className="blueul">
                            <p>·IOT模块接入</p>
                            <p>·硬件交互控制</p>
                            <p>·时空场景引用</p>
                        </div>
                        {bluebutton4}
                    </div>
                </div>
            </div>
        )
    }
}
