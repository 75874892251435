import React, { Component } from 'react'
import { Button } from 'antd';
import { Link } from 'react-router-dom'
import Img1 from '@assets/四明湖.png'
import './index.css'

export default class Case2 extends Component {
    state = {
        startitle: ['实时统计分析监测水雨情'],
        starli1: ['帮助管理和决策者掌握水库环境资源的布局分布'],
        starli2: ['为环境监管的数据联动、应急事故的指挥调度等提供科学的依据'],
        starli4: ['统计分析'],
        starli5: ['监测报警'],
        starli6: ['安防监控'],
    }
    render() {
        return (
            <div>
                <div>
                    <div className="startext">
                        <div className="startextul">
                            <h2>{this.state.startitle}</h2>
                            <ul className="floatleft11">
                                <li>{this.state.starli1}</li>
                                <li>{this.state.starli2}</li>
                            </ul>
                            <ul className="floatleft22">
                                <li>{this.state.starli4}</li>
                                <li>{this.state.starli5}</li>
                                <li>{this.state.starli6}</li>
                            </ul>
                        </div>
                        <Link to="/SimingLakeReservoir" className="starbutton">
                            <Button type="primary"  style={{ backgroundColor: '#2468F2',width:'150px' }}>
                                查看详情
                            </Button>
                        </Link>
                        <div className="starimg">
                            <img src={Img1} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
