import React, { Component } from 'react'
// import solutiongimg from './特色场景.png'
import './index.css'

export default class Solution extends Component {
    render() {
        return (
            <div>

                {
                    this.props.Solutiondiv1.map(item => {//这个地方通过this.props.arr接收到父组件传过来的arr，然后在{}里面进行js的循环
                        return (
                            <div className="solutiontext" key={item.sdh2}>
                                <div className="solutionimg1" >
                                    <img src={(item.imgs)} alt="" style={{ height: 83 }}></img>
                                </div>
                                <h2>{item.sdh2}</h2>
                                <p>
                                    {item.sdp}<br />
                                    {item.sdpbr1}<br />
                                    {item.sdpbr2}<br />
                                    {item.sdpbr3}<br />
                                    {item.sdpbr4}<br />
                                    {item.sdpbr5}<br />
                                    {item.sdpbr6}<br />
                                </p>
                            </div>
                        )
                    })
                }


            </div>
        )
    }
}
