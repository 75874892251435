import React, { Component } from 'react'
import Solutionswiper from './Solutionswiper'
import CaseM from './CaseM'
import tscj from '@assets/特色场景.png'
import ssjk from '@assets/实时监控.png'
import sjzh from '@assets/数据整合.png'
import VR from '@assets/VR浏览.png'
import AdvantagesM from './AdvantagesM'
import './index.scss'

export default class SolutionM extends Component {
    state = {
        jsys: [
            {
                img: tscj,
                title: '特色场景',
                p: '轻松应对各类人员数据分析需求新一代数据可视化产品',
                right: '10'
            },
            {
                img: ssjk,
                title: '实时监控',
                p: '打通数据生命周期各环节在新一代动感3D引擎的助力'
            },
            {
                img: sjzh,
                title: '数据整合',
                p: '轻松应对各类人员数据分析需求新一代数据可视化产品',
                right: '10'
            },
            {
                img: VR,
                title: 'VR浏览',
                p: '打通数据生命周期各环节在新一代动感3D引擎的助力'
            },
        ]
    }
    render() {
        return (
            <div>
                <div className="solutionmback">
                </div>
                <h1 className="solutionmbacktitle">数据可视化</h1>
                <div className="solutionmbody">
                    <div className="solutionmtitle"><h1>应用场景</h1></div>
                    <Solutionswiper />
                    {/* 平台结构 */}
                    <div className="solutionmtitle"><h1>平台框架</h1></div>
                    <img src={require('@assets/平台架构.png').default} alt="" style={{ width:'100%',display:'block', margin:'auto'}} />
                    {/* 技术优势 */}
                    <div className="solutionmtitle"><h1>技术优势</h1></div>
                    <div className="advantagesm">
                        <AdvantagesM jsys={this.state.jsys} />
                    </div>
                    {/* 相关案例 */}
                    <div className="solutionmtitle"><h1>相关案例</h1></div>
                    <CaseM/>
                </div>
            </div>
        )
    }
}
