import React, { Component } from 'react'
import { Carousel } from 'antd';
import './index.css'

export default class Yycj extends Component {
    render() {
        return (
            <div className="Applications">
                <div className="Applicationtitle"><h2>应用场景</h2></div>
                <Carousel draggable>
                    <div className="Applicationyy">
                        <div className="scenarios1"></div>
                        <div className="scenarios2"></div>
                        <div className="scenarios3"></div>
                        <div className="scenarios4"></div>
                        <div className="scenarios11"></div>
                        <div className="scenarios22"></div>
                        <div className="scenarios33"></div>
                        <div className="scenarios44"></div>
                        <h3 className="wisdom">智慧园区</h3>
                        <h3 className="Colleges">智慧景区</h3>
                        <h3 className="security">智慧湖泊</h3>
                        <h3 className="spot">智慧社区</h3>
                        <p className="wisdomp">助力园区构建一个统一的数据服务平台，实现高效、便捷的集中式管理</p>
                        <p className="Collegesp">三维导览、多元化介绍、多种待机模式助力游客游玩体验</p>
                        <p className="securityp">为环境监管的数据联动、应急事故的指挥调度等提供科学的依据</p>
                        <p className="spotp">为新型的社区提供一个直观、简化的平台，让工作者人人可用、人人会用、人人好用</p>
                    </div>
                    <div className="Applicationyy">
                        <div className="scenarios1"></div>
                        <div className="scenarios2"></div>
                        <div className="scenarios3"></div>
                        <div className="scenarios4"></div>
                        <div className="scenarios11"></div>
                        <div className="scenarios22"></div>
                        <div className="scenarios33"></div>
                        <div className="scenarios44"></div>
                        <h3 className="wisdom">智慧高校</h3>
                        <h3 className="Colleges">智慧场管</h3>
                        <h3 className="security">智慧工厂</h3>
                        <h3 className="spot">智慧工地</h3>
                        <p className="wisdomp">深层次整合挖掘校园信息化数据，有效的支持和辅助校园管理</p>
                        <p className="Collegesp">从区域——建筑——楼层——房间——物体，实现精细的可视化孪生管理</p>
                        <p className="securityp">利用物联网的技术和设备监控技术，实现生产线运行状态可视化、设备设施可视化</p>
                        <p className="spotp">运用信息化手段，通过三维设计平台，实现工程施工可视化智能管理</p>
                    </div>
                    <div className="Applicationyy">
                        <div className="scenarios1"></div>
                        <div className="scenarios11"></div>
                        <h3 className="wisdom">智慧医院</h3>
                        <p className="wisdomp">全面整合有线、无线、运维、安全等产品，通过更高效、智能的解决方案助推“智慧医院”</p>
                    </div>
                </Carousel>
            </div>

        )
    }
}
