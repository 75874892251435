import React, { Component } from 'react'
import './index.css'

export default class Functional extends Component {
        state=this.props.Function
    render() {
        return (
            <div className="Function">
                <div className="Functionleft">
                    <div className="functiontxt">
                        <h2>{this.state.h2}</h2>
                        <div className="blueline"></div>
                        <p>{this.state.p}</p>
                    </div>
                </div>
                <div className="Functionright">
                    <img src={this.state.img} alt="" />
                </div>
            </div>
        )
    }
}
