import React, { Component } from 'react'
import { Button } from 'antd';
import solutionimg from '@assets/四明湖大.png'
import { Link } from 'react-router-dom'
import './index.css'

export default class Solution1 extends Component {
    state = {
        p: '为管理和决策者掌握水库环境资源的布局分布、实时动态变化、环境监管的数据联动、应急事故的指挥调度等提供科学的依据'
    }
    render() {
        let userMessage;
        if (this.props.loggedIn) {
            userMessage = (
                ''
            )
        } else {
            userMessage = (
                <Link to='/SimingLakeReservoir'>
                    <Button ghost className="solutionbutton">立即查看</Button>
                </Link>
            )
        }
        return (
            <div>
                <div className="solutioncasetext">
                    <div className="solutioncasetextp">
                        <p>
                            {this.state.p}
                        </p>
                    </div>
                    <Link to='/Largegamountofmicrobrain'>
                        {userMessage}
                    </Link>
                </div>
                <div className="solutionimgdivs">
                    <div className="solutionimgdiv">
                        <img src={solutionimg} alt="" className="solutionimg" />
                    </div>
                </div>
            </div>

        )
    }
}
