import React, { Component } from 'react'
import './index.css'

export default class Applicationscenarios extends Component {
    render() {
        return (
            <div>
                <div className="Application" style={{marginBottom:'-70px'}}>
                    <div className="alsa"><h2>应用场景</h2></div>
                    <div className="scenarioss1"></div>
                    <div className="scenarioss2"></div>
                    <div className="scenarioss3"></div>
                    <div className="scenarioss4"></div>
                    <div className="scenarioss11"></div>
                    <div className="scenarioss22"></div>
                    <div className="scenarioss33"></div>
                    <div className="scenarioss44"></div>
                    <h3 className="wisdoms">智慧园区</h3>
                    <h3 className="Collegess">智慧园区</h3>
                    <h3 className="securitys">智慧园区</h3>
                    <h3 className="spots">智慧园区</h3>
                    <div className="wisdomps">
                        <p>助力园区高标准建设、高质量管理、高效化运营</p>
                    </div>
                    <p className="Collegesps">助力园区高标准建设、高质量管理、高效化运营</p>
                    <p className="securityps">助力园区高标准建设、高质量管理、高效化运营</p>
                    <p className="spotps">助力园区高标准建设、高质量管理、高效化运营</p>
                </div>
            </div>
        )
    }
}
