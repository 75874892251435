import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export default class Partner extends Component {
    state = {
        DynamicM1:
        {
            title: '“数字孪生城市”来了',
            p: '在一座物理世界的实体城市之外，是否还可能存在另一座虚拟世界的数字城市？',
            day: '7月6日'
        }
        ,
        DynamicM2:
        {
            title: '数据“活”了，腾讯位置服务推出移动端3D数据可视化图层',
            p: '大数据能力在应用端逐渐普及,在保证数据完整性的同时……',
            day: '8月18日'
        }
        ,
        DynamicM3:
        {
            title: '百度如流成2021百度世界最惊喜彩蛋 智能会议纪要炸场',
            p: '8月18日，2021百度世界大会召开，展示百度AI的诸项先进成果……',
            day: '8月19日'
        }
        ,
        DynamicM4:
        {
            title: '连接虚拟与现实的数字桥梁 NVIDIA开启元宇宙的无限想象',
            p: 'NVIDIA是全球图形运算行业和视觉运算行业的领袖，也是GPU的发明者。',
            day: '8月19日'
        }
        ,
    }
    render() {
        return (
            <div>
                <div className="largegmbody">
                    <div className="largegmtop">
                        <div className="backm" onClick={() => this.props.history.goBack()}>
                            <img src={require('@assets/路径.png').default} alt="" />
                            <span>返回</span>
                        </div>
                    </div>
                    <h1 style={{ fontWeight: 'bold' }}>连接虚拟与现实的数字桥梁 NVIDIA开启元宇宙的无限想象</h1>
                    <p style={{ color: '#787878', fontSize: '10px', textIndent: '0' }}>发布日期 2021/08/19/18:05</p>
                    <img src={require('@assets/4000.jpg').default} style={{ marginBottom: '30px' }} alt="" />
                    <p>“NVIDIA是全球图形运算行业和视觉运算行业的领袖，也是GPU的发明者。NVIDIA每年在GPU研发、科研方面投入数十亿美金来进行相关的探索和研究，大家在图形和视觉运算行业里看到的NVIDIA的技术、软件、硬件、平台等各方面的解决方案，都建立在NVIDIA的研究基础上。”NVIDIA中国区高级技术市场经理施澄秋表示，“NVIDIA在基础科学的进步上，与全球一流的产品和团队在早期便进行了紧密合作，并且把每一项研究成果由基础科学转换为生产力，最后落实到具体的技术和产品上，带给NVIDIA的客户，让全球视觉运算以及人工智能行业的用户都可以获益于NVIDIA的全新产品技术和研发成果，比如光线追踪、Omniverse、DLSS等等。”</p>
                    <h3>
                        NVIDIA扩展Omniverse平台 将数百万新用户带入元宇宙的世界
                    </h3>
                    <p>在SIGGRAPH 2021期间，NVIDIA发布了图形计算和AI领域的一系列创新成果，帮助客户和伙伴了解基于GPU平台的各种加速场景，以及NVIDIA的创新“秘籍”。NVIDIA research（NVIDIA研究院）是一所专注于探索前沿科技的独立研究机构，由来自全球各地的200多名科学家组成，有着广泛的研究领域，包括人工智能、计算机视觉、架构设计、自动驾驶、机器人、图形、电路，以及针对图形、电路、计算机视觉的编程系统、硬件架构等等，持续创新的理念使其成为了很多NVIDIA重磅新品的发源地，同时，依托于NVIDIA强大的硬件和软件能力，这些前沿的研究成果也可以迅速转化为最新的生产力工具，在Omniverse平台上得到展现。</p>
                    <p>这些技术包括渲染技术、材质技术、光影技术、光线追踪技术、照明技术、基于路径追踪的渲染技术等，以及基于物理、仿真、物理真实性的人物建模，基于GAN自动高品质高分辨率的图像生成，画面的自动填空，缺损像素的修补、插帧，还有基于GAN 2D到3D建模的自动生成等等。过去，光源模拟在没有Neural Radiance Cache技术时，电影画面只做一次光源迭代达到的画面并不真实，要做30次光源迭代则会在一帧上消耗数秒甚至数十秒，而借助NVIDIA最新的RTX GPU、Neural Radiance Cache等技术，可以实时进行30次光源迭代，实现电影级的画质渲染。
                    </p>
                    <p>类似的例子还发生在隧道场景的光线追踪渲染和基于RTX的直接照明，通过与世界级的产品团队合作，NVIDIA在不到一年的时间里，就可以把科学家的理论应用到RTXDI产品上。细节优化方面，在LOD自动生成、自动级别、自动LOD调整时，假设某一部分的树LOD需要数亿个多边形，当视觉拉远的时候，实际上并不需要渲染如此大量级的多边形，就可以通过减少LOD的等级来节约渲染量，让多边形渲染量缩减到2000万，但在使用者和观众的眼里看来仍然足够详实。
                    </p>
                    <p>在SIGGRAPH上，NVIDIA发布了RTX A2000 GPU，该产品采用小巧、节能的设计，适用于各种标准和小型工作站，具备加速设计工作流程中的AI和光线追踪技术，可以让更多用户从任何地方访问RTX技术。RTX A2000专为日常工作流程而设计，专业人士可以开发逼真的渲染、构建精确的物理模拟并使用AI加速工具。得益于NVIDIA RTX技术，创新实时设计以及性能行业可以获得质的飞跃。10月起，NVIDIA RTX A2000台式机GPU将搭载于华硕、BOXX Technologies、戴尔、惠普和联想等制造商生产的工作站，并通过NVIDIA全球经销合作伙伴提供。
                    </p>
                    <h3>NVIDIA发布RTX A2000 GPU 让更多专业人士能够使用RTX技术</h3>
                    <p>作为面向专业人士的高性能薄型双路GPU，NVIDIA RTX A2000结合了最新一代RT Core、Tensor Core和CUDA Core与6GB ECC显存，采用了NVIDIA Ampere架构的最新技术，包括：第二代RT Core——适用于所有专业工作流程的实时光线追踪，开启RTX后，渲染性能比上一代提高5倍；第三代Tensor Core——用于在GPU架构中支持AI增强工具和应用；CUDA Core——吞吐量比上一代FP32提高了多达2倍，适用于大型图形和计算工作负载；6GB的GPU显存——支持ECC内存，这是NVIDIA首次在其2000系列GPU中支持ECC内存以实现无差错计算；PCIe Gen 4——吞吐量增加一倍，带宽比上一代提高40%以上，用于加速GPU的进出数据路径。</p>
                    <p>通过与OEM、ODM、分销商、ISV、软硬件平台解决方案商等紧密配合，NVIDIA在3D建模领域提升了50%的性能，在FP32模拟方面提升了200%，在渲染性能上有5倍提升。首批采用RTX A2000的公司包括Avid媒体公司、Cuhaci&Peterson和Gilbane建筑公司。Cuhaci&Peterson数字业务部总监Steven Blevins表示：“与上一代GPU相比，NVIDIA RTX A2000加入了RT Core，为逼真的可视化处理带来了惊人的渲染速度。”Gilbane建筑公司虚拟设计和建筑经理Ken Grothman也对NVIDIA RTX A2000的小尺寸、低功耗设计称赞有加：“这款产品适用于几乎所有现有的工作站机箱。”</p>
                    <h3>用于实现3D打印部件轻量化的自定义晶格枕状结构（图片来自Avid）</h3>
                    <img src={require('@assets/5000.jpg').default} style={{ marginBottom: '30px' }} alt="" />
                    <h3>Autodesk Revit中的建筑模型与点云数据（图片来自Gilbane）</h3>
                    <p>Omniverse是NVIDIA针对专业图形图像领域的一站式在线分工合作优化工作流的平台，支持各种基于NVIDIA RTX技术的产品，而在SIGGRAPH期间，NVIDIA也宣布了全球首个为元宇宙建立提供基础的模拟和协作平台——NVIDIA Omniverse将通过与Blender和Adobe集成来实现大规模扩展，并将向数百万新用户开放。</p>
                    <p>要知道，任何应用规模的使用者或任何一个功能齐全的经济体，都需要做到跨平台实时数字资产的分享，或者是数字孪生，即在产品、技术落地之前，先将其放在虚拟世界里，做模拟或者验证。Omniverse所做的，就是把虚拟世界和真实世界的资产连接在一起，所有的个人、公司、实体都可以在虚拟和现实之间做自由转换。“我们可以设想一下，Omniverse是NVIDIA实现元宇宙的基础技术和平台产品。”施澄秋称。</p>
                    <p>以往，要把传统3D工作流这样的复杂工程放到数字和模拟世界中，需要让拥有各类技能且分布在全球各地不同的团队、艺术家、设计师、工程师连接起来，而这些群体所使用的系统可能完全不一样，例如Autodesk、Adobe、3dsMax、Maya、Blender等各式各样的软件，这就需要一个能够打通所有工具的数字资产平台。与此同时，3D工作流程也越来越复杂，不仅要支持实时光线追踪、人工智能，还要支持物理仿真、物理模拟，以达到每个组件的物理准确性。</p>
                    <p>面对高速增长的需求和苛刻的任务要求，NVIDIA认为3D工作流在任何行业的任何地方都是必不可少的，无论是AEC、M&E、传统设计制造领域，还是科学、大数据可视化、机器人、自动驾驶车辆、自主机器等行业，都需要实现精确且物理真实的照片级或电影级的3D仿真，来完成数字孪生构想，让产品物理存在于这个星球的表面之前，能够在虚拟世界里先做数字化、虚拟化的仿真验证。</p>
                    <p>过去，汽车行业使用模型车做风洞实验时，要获得这辆车的风阻和在流体力学下的表现等测试数据，帮助车厂做前期的改正或修正。而借助Omniverse和NVIDIA RTX GPU，在元宇宙的虚拟世界就可以先做出样式，再利用NVIDIA的PhysX技术和NVIDIA GPU模拟风阻以获得风阻系数的仿真参数，之后交给前端做建模的用户，用不同的软件和工具优化前杠、前挡、机盖、A柱等设计。</p>
                    <p>Omniverse既提供了协同方案，又解决了数字存储，还可以改善数据资产的安全性。Omniverse具有文件校验机制，专业的A系列显卡全部配备了ECC校验的缓存，在Omniverse平台上可以应用户要求，采用端到端加密的方式存储和使用数字资产，解决了数字资产的安全问题和不易于管理的问题。Omniverse的USD技术能够实现全局数字资产的交换和存取，在虚拟世界中用各种ISV工具来第一时间使用同一套数字资产，通过Omniverse Nucleus数据库协作引擎，实现3D资产和场景描述的实时在线交换。对于用户来说，可以用iPad导入和导出，或借助NVIDIA CloudXR技术使用VR设备传送。</p>
                    <p>最新的OmniSurface技术可以实现实时画面管理，例如人脸的实时渲染。在传统的M&E行业，基于皮肤、毛发、表情、五官等管理的综合体人类的渲染非常复杂，而NVIDIA的技术支持在Omniverse中实时渲染数字人，例如基于Omniverse的机器人仿真、自驾车仿真等技术。当前，自动驾驶功能存在行人误判等问题，如果将街头的人脸图片识别为行人，会降低驾乘人员的体验。利用数字人实时渲染技术，在Omniverse中可以构建更逼真的、更符合物理定律的人类模型，帮助车辆、自主机器、机器人等更好地识别出真实的人类。应用过程中，拖动“zoom in”按钮可以栩栩如生地表现人物的皮肤、毛发、年龄等信息，甚至头发的蓬松程度、发型、胡须的样子都可以更改。</p>
                    <p>Blender是全球领先的开源3D动画工具，现在该工具将加入通用场景描述（USD）支持，使艺术家能够访问Omniverse制作流程。Adobe正在与NVIDIA合作开发一个为Omniverse提供Substance Material支持的Substance 3D插件，为Omniverse和Substance 3D用户提供全新素材编辑功能。Omniverse上也会有针对开发者做的自有二次开发工具的综合体， 例如微服务、扩展组件，Omniverse Kit在Omniverse源代码中有超过200个预建好的扩展程序。以前，数以百万计的用户只能在自有的环境下开发，如今已经可以利用Omniverse混合型云原生平台的服务做二次开发，该平台支持了数十款全球主流的ISV工具。</p>
                    <p>SHoP建筑事务所（SHoP Architects）、《南方公园》（South Park）制作工作室和洛克希德·马丁（Lockheed Martin）等500多家公司的专业人士正在评估该平台。自去年12月公测版上线以来，已有超过5万名个人创作者下载了Omniverse。皮克斯的开源USD是Omniverse协作和模拟平台的基础，可以让大型团队在一个共享的3D场景中同时使用多种软件应用工作。该开源标准基础能够为软件合作伙伴提供多种扩展和连接Omniverse的方式，无论是通过USD采用和支持，还是创建插件，或者通过Omniverse Connector。苹果、皮克斯与NVIDIA共同为USD带来了先进的物理功能，采用开源标准，支持数十亿设备的3D工作流程。Blender和NVIDIA进行了合作，为即将发布的Blender 3.0以及使用该软件的数百万名艺术家提供USD支持。NVIDIA为Blender 3.0 alpha USD添加了USD和素材支持，将很快开放给创作者使用。</p>
                    <p>经过三个季度的OBT，NVIDIA超过百人的Omniverse团队持续在解决用户反馈的问题并进行功能完善和升级。同时，NVIDIA在中国上海的Omniverse团队也在进行本土化应用的开发。目前，中国的Omniverse客户覆盖设计、M&E、电视台等领域，其中就包括中央广播电视总台使用NVIDIA Omniverse制作了太空纪录片《飞向月球》。第三季度，Omniverse Enterprise会正式向用户进行推送。</p>
                    <p>“如今，NVIDIA对自身的定位是一个‘Total Solution Platform Provider’（全解决应用方案的平台），这个平台中包括了软件和硬件，更有客户、SI、OEM、ODM、ISP、ISV等丰富的生态参与者。”施澄秋说，“我们希望用户通过一个Omniverse入口就能实现业务价值，不用再去考虑服务器、预装软件、调度程序等问题，因为NVIDIA已经把整个平台帮用户建好了，并且可以应用到等各个云平台上。这就是NVIDIA对Omniverse未来的希望——做一个完整的解决方案平台提供者，而这个平台上承托了各式各样的软硬件合作伙伴。”</p>
                    <h3>注明：本公众号转载文章仅用于分享，不用于任何商业用途。如涉及版权问题，敬请后台联络授权或议定合作，我们会按照版权法规定第一时间为您妥善处理。</h3>
                </div>
                <div className="separatedm"></div>
                <div className="DynamicM" style={{ margin: 'auto' }}>
                    <div className="solutionmtitle"><h1>其他案例推荐</h1></div>
                    {/* 第一个动态 */}
                    <Link to="/ThePartnerM">
                        <div className="DynamicMbox">
                            <img src={require('@assets/动态1.png').default} alt="" />
                            <div className="DynamicMtxt">
                                <h2>{this.state.DynamicM1.title}</h2>
                                <span>{this.state.DynamicM1.day}</span>
                                <p>{this.state.DynamicM1.p}</p>
                            </div>
                        </div>
                    </Link>
                    {/* 第二个动态 */}
                    <Link to="/ThePartnerM2">
                        <div className="DynamicMbox">
                            <img src={require('@assets/动态2.png').default} alt="" />
                            <div className="DynamicMtxt">
                                <h2>{this.state.DynamicM2.title}</h2>
                                <span>{this.state.DynamicM2.day}</span>
                                <p>{this.state.DynamicM2.p}</p>
                            </div>
                        </div>
                    </Link>
                    {/* 第三个动态 */}
                    <Link to="/ThePartnerM3">
                        <div className="DynamicMbox">
                            <img src={require('@assets/动态3.png').default} alt="" />
                            <div className="DynamicMtxt">
                                <h2>{this.state.DynamicM3.title}</h2>
                                <span>{this.state.DynamicM3.day}</span>
                                <p>{this.state.DynamicM3.p}</p>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
        )
    }
}
