import React, { Component } from 'react'


export default class LargegM extends Component {
    render() {
        return (
            <div className="largegmbody">
                <div className="largegmtop">
                    <div className="backm" onClick={() => this.props.history.goBack()}>
                        <img src={require('@assets/路径.png').default} alt="" />
                        <span>返回</span>
                    </div>
                </div>
                <h1>中国计量大学——量大微脑</h1>
                <h4>助力园区高标准建设、高质量管理、高效化运营</h4>
                <p>
                    中国计量大学是一所以计量、标准、质量、市场监管
                    和检验检疫为办学特色的本科院校。学校前身是1978年由国家计量总局创建的杭州计量学校，1985年经教育部批准升格为中国计量学院，2016年更名为中国计量学，2019年成为浙江省与国家市场监管总局共建大学和“省市共建”重点大学。在浙江省普通本科高校分类评价中，连续四年位列多科性教学研究型高校、硕士培养高校第一名，2020年列软科中国大学排名硕士点高校第一。</p>
                <p>        中国计量大学是一所以计量、标准、质量、市场监管
                    和检验检疫为办学特色的本科院校。学校前身是1978年由国家计量总局创建的杭州计量学校，1985年经教育部批准升格为中国计量学院，2016年更名为中国计量学，2019年成为浙江省与国家市场监管总局共建大学和“省市共建”重点大学。在浙江省普通本科高校分类评价中，连续四年位列多科性教学研究型高校、硕士培养高校第一名，2020年列软科中国大学排名硕士点高校第一。</p>
                    <img src={require('@assets/学生公寓.png').default} alt="" />
                    <img src={require('@assets/办事大厅概况.png').default} alt="" />
            </div>
        )
    }
}
