import React, { Component } from 'react'
import { Button } from 'antd';
import 'antd/dist/antd.css';
import './index.css'

export default class Enterprise extends Component {

    render() {
        return (
            <div>
                {
                    this.props.arr.map(item => {//这个地方通过this.props.arr接收到父组件传过来的arr，然后在{}里面进行js的循环
                        return (
                            <Button className="enterprisebutton" key={item.aboutname}>
                                <p>{item.aboutname}</p>
                            </Button>
                        )
                    })
                }

            </div>
        )
    }
}
