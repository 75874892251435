import React, { Component } from 'react'
import { Accordion, List } from 'antd-mobile';
import 'antd-mobile/dist/antd-mobile.css';
import './index.scss'
import { Link } from 'react-router-dom';

export default class Footer extends Component {
    render() {
        return (
            <div className="FooterM">

                <div style={{ marginTop: 10, marginBottom: 10 }}>
                    <Accordion defaultActiveKey="0" className="my-accordion" onChange={this.onChange}>
                        <Accordion.Panel header="解决方案">
                            <List className="my-list">
                                <Link to="/TheSolutionM">
                                    <List.Item>数字可视化</List.Item>
                                </Link>
                                <List.Item>虚拟仿真</List.Item>
                                <List.Item>AR、VR创意互动</List.Item>
                                <List.Item>其他方案</List.Item>
                            </List>
                        </Accordion.Panel>
                    </Accordion>
                    <ul>
                        <Link to="/TheCaseshowsM" style={{color:'white'}}>
                            <li>案例展示</li>
                        </Link>
                        <Link to="/TheProductM" style={{color:'white'}}>
                            <li>产品技术</li>
                        </Link>
                        <Link to="/TheAboutM" style={{color:'white'}}>
                            <li>关于我们</li>
                        </Link>
                    </ul>
                    <div className="footerm">
                        <span>©XUANYANKEJI</span> <br />
                        <span>京ICP备15017470号-3       Copyright</span>
                    </div>
                </div>
            </div>
        )
    }
}
