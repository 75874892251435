import React, { Component } from 'react'
import { Button } from 'antd';
import { Link } from 'react-router-dom'
import Img1 from '@assets/承载园区.png'
import './index.css'


export default class Case1 extends Component {

    state = {
        startitle: ['园区综合安防展示的可视化决策辅助平台'],
        starli1: ['平台以三维场景为载体对关键数据进行综合展示、挖掘分析、应用'],
        starli2: ['多个维度进行日常运行监测、管理、预判，以及突发事件的应急指挥调度管理'],
        starli3: ['为园区管理者提高园区安防管理效率，提供数据决策支撑'],
        starli4: ['决策平台'],
        starli5: ['调度管理'],
        starli6: ['数据决策'],
        caseatp: true
    }
    play1 = () => {
        this.setState({ caseatp: !this.state.caseatp })
    }
    render() {
        return (
            <div>
                <div className="startext">
                    <div className="startextul">
                        <h2>{this.state.startitle}</h2>
                        <ul className="floatleft11">
                            <li>{this.state.starli1}</li>
                            <li>{this.state.starli2}</li>
                            <li>{this.state.starli3}</li>
                        </ul>
                        <ul className="floatleft22">
                            <li>{this.state.starli4}</li>
                            <li>{this.state.starli5}</li>
                            <li>{this.state.starli6}</li>
                        </ul>

                    </div>
                    <Link to='/Wisdompark' className="starbutton">
                        <Button type="primary" style={{ backgroundColor: '#2468F2', width: '150px' }}>
                            查看详情
                        </Button>
                    </Link>
                    <div className="starimg" >
                        <img src={Img1} alt="" onMouseMove={this.play1} />
                    </div>
                </div>
            </div>
        )
    }
}