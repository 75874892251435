import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import './index.scss'

export default class Partner extends Component {
    state = {
        DynamicM2:
        {
            title: '数据“活”了，腾讯位置服务推出移动端3D数据可视化图层',
            p: '大数据能力在应用端逐渐普及,在保证数据完整性的同时……',
            day: '8月18日'
        }
        ,
        DynamicM3:
        {
            title: '百度如流成2021百度世界最惊喜彩蛋 智能会议纪要炸场',
            p: '8月18日，2021百度世界大会召开，展示百度AI的诸项先进成果……',
            day: '8月19日'
        }
        ,
        DynamicM4:
        {
            title: '连接虚拟与现实的数字桥梁 NVIDIA开启元宇宙的无限想象',
            p: 'NVIDIA是全球图形运算行业和视觉运算行业的领袖，也是GPU的发明者。',
            day: '8月19日'
        }
        ,
    }
    render() {
        return (
            <div>
                <div className="largegmbody">
                    <div className="largegmtop">
                        <div className="backm" onClick={() => this.props.history.goBack()}>
                            <img src={require('@assets/路径.png').default} alt="" />
                            <span>返回</span>
                        </div>
                    </div>
                    <h1>“数字孪生城市”来了</h1>
                    <p style={{ color: '#787878', fontSize: '10px' }}>发布日期 2021/07/06/16:16</p>
                    <img src={require('@assets/1000.jpg').default} style={{ marginBottom: '30px' }} alt="" />
                    <p style={{ textAlign: 'center' }}>图片来源：视觉中国</p>
                    <p>在一座物理世界的实体城市之外，是否还可能存在另一座虚拟世界的数字城市？二者一体同胞，虚实相生，如影随形，同生共长。</p>
                    <p>
                        这是一个变革和创新的时代，一切皆可编程，万物均可互联。把不可能变成可能的种种全新场景，会不断颠覆传统认知。数字孪生城市的出现，也将刷新人们对未来城市的想象。
                    </p>
                    <p>短短数年，数字孪生从一个技术概念，演变成一种新转型路径、新变革动力。数字孪生城市正在成为智慧城市的升级版和必选项。它赋予实体城市新的互联网基因——通过信息技术的深度应用,给城市一个数字克隆体，使不可见的城市隐形秩序显性化，城市肌体每个毛细血管的一举一动尽在掌握中。</p>
                    <p>应大湾区的数字孪生深度开发者的交流需求，我们特别请到51总部SuperAPI的研发负责人来到现场，与湾区的技术咖们来一场火花对对碰。据说SuperAPI又双叒更新啦！这次会带来哪些新功能，SuperAPI又有哪些高阶玩法？吐槽、提问、答疑、探讨...技术宅们，请准备好你们犀利的问题，千万别放过我们的研发小哥哥，快来现场砸场子！
                    </p>
                    <p>方兴未艾的数字孪生城市变革的不仅是传统城市规划建设，而且助推城市治理体系和治理能力现代化。城市治理可望向“智”理升级，城市病可望被“智”疗，未来城市治理和公共服务将不再是盲人摸象。
                    </p>
                    <p>一切才刚刚开始。在数字城市规划落地实施过程中，蹭热度炒概念、重建设轻运营、典型场景应用不足、安全风险加剧等苗头性问题也随之显现，有待校准航向，行稳致远。
                    </p>
                    <h3>——确保数字孪生城市网络安全。</h3>
                    <p>广东省政务服务数据管理局在2020年11月牵头发起了“数字政府网络安全产业联盟”,聚焦数字政府网络安全的未来趋势与核心技术,把网络安全的“产学研用”紧密结合起来,提升数字政府整体安全水平。目前，该联盟的成员单位包括广东省政务服务数据管理局、深圳市政务服务数据管理局、相关部委下属研究机构、广州大学及腾讯、华为、深信服公司等。</p>
                    <p>齐向东表示,针对数字孪生城市网络安全问题,不是加强某个环节就能解决的,而要提升整体防护水平,建立完整的网络安全体系,制定自主网络安全体系框架的国家标准。</p>
                    <h3>——加强基础研究,强化产业生态合作。</h3>
                    <p>一方面,要持续加强技术领域的基础理论研究和应用场景验证,筑牢数字孪生城市的根基；另一方面,加强战略、技术、标准、市场等全方位协作,打造一个强有力的产业生态,为数字孪生城市在知识、技术、数据、算法、工具等方面的智力集成提供支撑。</p>
                    <p>半月谈记者了解到,《深圳经济特区数据条例》的立法工作正在推进中,相关规章制度的完善将为数字孪生城市建设保驾护航。各地政府部门应当主动开放数据资源,为数字孪生城市技术方案和应用场景的迭代开发与测试验证创造条件。数字孪生城市建设中，尤其要更加注重公共数据的使用范围,进一步立法解决数据伦理问题，避免信息滥用,同时更好地帮助老年人跨越“数字鸿沟”。</p>
                    <p>来源：《半月谈内部版》2021年第7期 原标题：《给城市一个数字孪生兄弟》</p>
                    <p>策划、编辑：李建发</p>
                    <p>责编：秦黛新 米湘月（实习生）</p>
                    <p>校对：郭艳慧</p>
                    <h3>本文来源：半月谈</h3>
                    <h3>注明：本公众号转载文章仅用于分享，不用于任何商业用途。如涉及版权问题，敬请后台联络授权或议定合作，我们会按照版权法规定第一时间为您妥善处理。</h3>
                </div>
                <div className="separatedm"></div>
                <div className="DynamicM" style={{margin:'auto'}}>
                    <div className="solutionmtitle"><h1>其他案例推荐</h1></div>
                    {/* 第二个动态 */}
                    <Link to="/ThePartnerM2">
                        <div className="DynamicMbox">
                            <img src={require('@assets/动态2.png').default} alt="" />
                            <div className="DynamicMtxt">
                                <h2>{this.state.DynamicM2.title}</h2>
                                <span>{this.state.DynamicM2.day}</span>
                                <p>{this.state.DynamicM2.p}</p>
                            </div>
                        </div>
                    </Link>
                    {/* 第三个动态 */}
                    <Link to="/ThePartnerM3">
                        <div className="DynamicMbox">
                            <img src={require('@assets/动态3.png').default} alt="" />
                            <div className="DynamicMtxt">
                                <h2>{this.state.DynamicM3.title}</h2>
                                <span>{this.state.DynamicM3.day}</span>
                                <p>{this.state.DynamicM3.p}</p>
                            </div>
                        </div>
                    </Link>
                    <Link to="/ThePartnerM4">
                        <div className="DynamicMbox">
                            <img src={require('@assets/动态444.png').default} alt="" />
                            <div className="DynamicMtxt">
                                <h2>{this.state.DynamicM4.title}</h2>
                                <span>{this.state.DynamicM4.day}</span>
                                <p>{this.state.DynamicM4.p}</p>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
        )
    }
}
